import {Outlet, useNavigate} from "react-router-dom"
import React, {useEffect} from "react"

import {
    Navbar,
    Button,
} from "@material-tailwind/react";
import {auth} from "../firebase";

const Layout = () => {
    const navigate = useNavigate();
    const user = auth.currentUser;

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                navigate("/dashboard");
            }
        })

        document.title = "GBI Dashboard"
    });

    const navList = (
        <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">

        </ul>
    )

    /* IN UL
    <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="flex items-center gap-x-2 p-1 font-medium"
            >
                <div>
                    <FontAwesomeIcon icon={faHouse}/>
                </div>
                <a href="/" className="flex items-center">
                    Home
                </a>
            </Typography>
     */
    if (user == null) {
        return (
            <div className="flex flex-col h-screen">
                <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
                    <div className="flex items-center justify-between text-blue-gray-900">
                        <img
                            alt="Logo"
                            className="h-9"
                            src="/gbidashboardlogo.png"
                        />
                        <div className="flex items-center gap-4">
                            <div className="mr-4 hidden lg:block">{navList}</div>
                            <div className="flex items-center gap-x-1">
                                <a href="/login">
                                    <Button
                                        ripple={false}
                                        variant="gradient"
                                        size="sm"
                                        className="block w-full select-none rounded-lg bg-gradient-to-tr from-gbiblue-100 to-gbiblue-200 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gbiblue-100/10 transition-all hover:shadow-lg hover:shadow-gbiblue-100/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    >
                                        <span>Inloggen</span>
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Navbar>
                <main className="flex-1">
                    <Outlet/>
                </main>
                <footer>
                    <span className="block border-t border-blue-gray-50"/>
                    <p className="block font-sans text-base antialiased font-normal leading-relaxed text-center text-blue-gray-900 mb-4 mt-4">
                        © 2024 GBI Dashboard
                    </p>
                </footer>
            </div>
        );
    }
}

export default Layout;