import LogIn from './pages/LogIn';
import NotFound from "./pages/NotFound";
import NotLoggedin from "./pages/NotLoggedin";
import DashboardApp from './DashboardApp';
import AdminApp from './AdminApp';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import React from "react";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<NotLoggedin />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="/login" element={<LogIn />} />
                <Route path="/dashboard/*" element={<DashboardApp />}/>
                <Route path="/admin/*" element={<AdminApp />}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App