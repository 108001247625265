import '../output.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

function NotLoggedin() {
    return (
        <div className="flex h-full">
            <div className="text-center m-auto">
                <div>
                    <p className="text-5xl p-6">Geen toegang</p>
                    <p>Log in om het dashboard te bekijken</p>
                    <p><a href="/login"><strong>Inloggen</strong><FontAwesomeIcon className="ml-3" icon={faArrowRight} /></a></p>
                </div>
            </div>
        </div>
    );
}

export default NotLoggedin;
