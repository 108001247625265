import '../output.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {useEffect} from "react";

function NotFound() {
    useEffect(() => {
        document.title = `GBI Dashboard | 404`
    })

    return (
        <div className="flex h-full">
            <div className="text-center m-auto">
                <div>
                    <p className="text-5xl p-6">404</p>
                    <p>Oeps.. Deze pagina kunnen we niet vinden :/</p>
                    <p><a href="/"><strong>Terug naar home</strong><FontAwesomeIcon className="ml-3" icon={faArrowRight} /></a></p>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
