import {Route, Routes} from "react-router-dom";
import AdminLayoutLoggedin from "./pages/AdminLayout-Loggedin";
import React, {useEffect, useState} from "react";
import Admin from "./pages/Admin";
import {collection, getDocs} from "firebase/firestore";
import {auth, db} from "./firebase";
import CompanyAdmin from "./pages/CompanyAdmin";

const AdminApp = () => {
    const [gebruikers, setGebruikers] = useState([])
    const [outletElement, setOutletElement] = useState()

    useEffect(() => {
        if (gebruikers.length === 0) {
            getUsers()
        }
    })

    async function getUsers() {
        let gebruikersCache = []
        const gebruikersCol = collection(db, "users");
        const gebruikersSnapshot = await getDocs(gebruikersCol);
        gebruikersSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            gebruikersCache.push(
                {
                    uuid: doc.data()["uuid"],
                    firstname: doc.data()["name"]["first"],
                    lastname: doc.data()["name"]["last"],
                    email: doc.data()["email"],
                    role: doc.data()["role"],
                    gbiid: doc.data()["gbiid"],
                    requestedDeletion: doc.data()["requestedDeletion"]
                }
            )
        });
        setGebruikers(gebruikersCache)
    }

    useEffect(() => {
        if (gebruikers.filter(item => item.uuid === auth.currentUser.uid).map(item => item.role)[0] === "admin") {
            setOutletElement(<Admin/>)
        }
        if (gebruikers.filter(item => item.uuid === auth.currentUser.uid).map(item => item.role)[0] === "companyadmin") {
            setOutletElement(<CompanyAdmin/>)
        }
    }, [gebruikers])

    return (
        <Routes>
            <Route path="/" element={<AdminLayoutLoggedin/>}>
                <Route index element={outletElement}/>
                <Route path=":vpagestring" element={outletElement}>
                    <Route path="*" element={outletElement}/>
                </Route>
            </Route>
        </Routes>
    )
}

export default AdminApp