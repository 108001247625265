import '../output.css';
import React, {useEffect, useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth";
import {collection, getDocs, setDoc, doc, deleteDoc, updateDoc} from "firebase/firestore";
import {auth, db} from "../firebase";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemPrefix,
    Select,
    Tooltip,
    Typography,
    Option, Alert, Drawer, CardHeader
} from "@material-tailwind/react";

// Solid icons
import UserGroupIconSolid from "@heroicons/react/24/solid/UserGroupIcon"
import BuildingOffice2IconSolid from "@heroicons/react/24/solid/BuildingOffice2Icon"
import WrenchScrewdriverIconSolid from "@heroicons/react/24/solid/WrenchScrewdriverIcon"

// Outline icons
import UserGroupIconOutline from "@heroicons/react/24/outline/UserGroupIcon"
import BuildingOffice2IconOutline from "@heroicons/react/24/outline/BuildingOffice2Icon"
import WrenchScrewdriverIconOutline from "@heroicons/react/24/outline/WrenchScrewdriverIcon"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrashCan,
    faPencil,
    faUserPlus,
    faPlus,
    faPersonDigging,
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import {initializeApp} from "firebase/app";
import { Mail, Token } from "../apiHandler";
import {wait} from "@testing-library/user-event/dist/utils";

function AlertIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
        >
            <path
                fillRule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function Admin() {
    let navigate = useNavigate();

    const {vpagestring} = useParams()
    const [bedrijven, setBedrijven] = useState([])
    const [gebruikers, setGebruikers] = useState([])

    useEffect(() => {
        document.title = `GBI Dashboard | Admin`

        if (bedrijven.length === 0) {
            getCompanies()
        }

        if (gebruikers.length === 0) {
            getUsers()
        }
    })

    async function getCompanies() {
        let bedrijvenCache = []
        const bedrijvenCol = collection(db, "gbibedrijven");
        const bedrijvenSnapshot = await getDocs(bedrijvenCol);
        bedrijvenSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            bedrijvenCache.push(
                {
                    id: doc.data()["gbiid"],
                    name: doc.data()["name"]
                }
            )
        });
        setBedrijven(bedrijvenCache)
    }

    const addCompany = async (e) => {
        e.preventDefault();

        if (bedrijfsnaamField !== '') {
            let maxValue = 0;
            for (let i = 0; i < bedrijven.length; i++) {
                if (bedrijven[i].id > maxValue) {
                    maxValue = bedrijven[i].id;
                }
            }

            const gbiid = (parseInt(maxValue) + 1).toString()

            await setDoc(doc(db, "gbibedrijven", gbiid), {
                gbiid: gbiid,
                name: bedrijfsnaamField,
            });

            getCompanies()
        }
    }

    const deleteCompany = async (gbiid) => {
        await deleteDoc(doc(db, "gbibedrijven", gbiid));

        getCompanies()
    }

    const requestDeleteUser = async (uuid) => {
        let firstname
        let lastname
        let email

        gebruikers.forEach(item => {
            if (item["uuid"] === uuid) {
                firstname = item["firstname"]
                lastname = item["lastname"]
                email = item["email"]

                const gbiid = item["gbiid"]

                Mail.send("RequestedDeletion", {
                    uuid: uuid,
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    company: bedrijven.filter(item => item.id === gbiid).map(item => item.name),
                    recipientEmail: gebruikers.filter(item => item.role === "admin").map(item => item.email),
                    rFirstname: gebruikers.filter(item => item.uuid === auth.currentUser.uid).map(item => item.firstname),
                    rLastname: gebruikers.filter(item => item.uuid === auth.currentUser.uid).map(item => item.lastname),
                    rEmail: gebruikers.filter(item => item.uuid === auth.currentUser.uid).map(item => item.email)
                }).then(async (result) => {
                    if (result["isError"] === false) {
                        await updateDoc(doc(db, "users", uuid), {
                            requestedDeletion: true
                        })

                        getUsers()
                    }
                })
            }
        })
    }

    const editCompany = async (data) => {
        await updateDoc(doc(db, "gbibedrijven", data["id"]), {
            name: bedrijfsnaamField
        });

        getCompanies()
    }

    const editUser = async (data) => {
        if ((firstnameField !== "") && (lastnameField !== "") && (emailField !== "") && (roleSelectorField !== "") && (companySelectorField !== "")) {
            await updateDoc(doc(db, "users", data["uuid"]), {
                name: {first: firstnameField, last: lastnameField},
                email: emailField,
                role: roleSelectorField,
                gbiid: companySelectorField,
            });

            handleOpenDialog()
            getUsers()

        } else {
            setAlertMessage("Kon gebruiker niet bewerken. Controleer of alle velden juist zijn ingevuld.")
            setShowAlert(true)
        }
    }

    async function getUsers() {
        let gebruikersCache = []
        const gebruikersCol = collection(db, "users");
        const gebruikersSnapshot = await getDocs(gebruikersCol);
        gebruikersSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            gebruikersCache.push(
                {
                    uuid: doc.data()["uuid"],
                    firstname: doc.data()["name"]["first"],
                    lastname: doc.data()["name"]["last"],
                    email: doc.data()["email"],
                    role: doc.data()["role"],
                    gbiid: doc.data()["gbiid"],
                    requestedDeletion: doc.data()["requestedDeletion"]
                }
            )
        });
        setGebruikers(gebruikersCache)
    }

    const addUser = async () => {
        if ((firstnameField !== "") && (lastnameField !== "") && (emailField !== "") && (roleSelectorField !== "") && (companySelectorField !== "")) {
            var config = {
                apiKey: process.env.REACT_APP_FB_API_KEY,
                authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN
            };

            var secondaryApp = initializeApp(config, "Secondary");

            const password = Math.random().toString(36).slice(-8);

            createUserWithEmailAndPassword(getAuth(secondaryApp), emailField, password)
                .then(function (firebaseUser) {
                    //Add user to firestore
                    setDoc(doc(db, "users", firebaseUser.user.uid), {
                        uuid: firebaseUser.user.uid,
                        name: {first: firstnameField, last: lastnameField},
                        email: emailField,
                        role: roleSelectorField,
                        gbiid: companySelectorField,
                        requestedDeletion: false
                    });

                    getAuth(secondaryApp).signOut();

                    Mail.send("NewUser", {
                        recipientFirstname: firstnameField,
                        recipientEmail: emailField,
                        password: password
                    })

                    handleOpenDialog()
                    getUsers()
                })
                .catch((error) => {
                    setAlertMessage("Kon gebruiker niet aanmaken. Controleer of alle velden juist zijn ingevuld.")
                    setShowAlert(true)
                });
        } else {
            setAlertMessage("Kon gebruiker niet aanmaken. Controleer of alle velden juist zijn ingevuld.")
            setShowAlert(true)
        }
    }

    const sendMail = async (data) => {
        if ((firstnameField !== "") && (emailField !== "") && (templateSelectorField !== "")) {
            setIsLoading(true)
            const result = await Mail.send(templateSelectorField, {
                recipientFirstname: firstnameField,
                recipientEmail: emailField
            })

            if (result["isError"] === false) {
                handleOpenDialog()
            } else {
                setIsLoading(false)
                setAlertMessage(`Kon mail niet versturen. (${result["response"]})`)
                setShowAlert(true)
            }
        } else {
            setAlertMessage("Kon mail niet versturen.")
            setShowAlert(true)
        }
    }

    const welcome = (
        <div className="text-center m-auto">
            <p className="text-5xl p-6">Welkom</p>
            <p>Selecteer een item in de zijbalk om te beginnen</p>
        </div>
    )

    let vpage
    if (vpagestring === "gebruikers") {
        vpage = 1
    } else if (vpagestring === "bedrijven") {
        vpage = 2
    } else if (vpagestring === "devtools") {
        vpage = 99
    } else {
        vpage = 0
    }

    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const openDrawer = () => setOpenSideDrawer(true);
    const closeDrawer = () => setOpenSideDrawer(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [selected, setSelected] = useState(vpage);

    const [listData, setListData] = useState()
    const [viewData, setViewData] = useState(welcome)
    const [lastselected, setLastSelected] = useState(0)

    const [bedrijfsnaamField, setBedrijfsnaamField] = useState("");
    const [firstnameField, setFirstnameField] = useState("");
    const [lastnameField, setLastnameField] = useState("");
    const [emailField, setEmailField] = useState("");
    const [roleSelectorField, setRoleSelectorField] = useState("");
    const [companySelectorField, setCompanySelectorField] = useState("");

    const [templateSelectorField, setTemplateSelectorField] = useState("");

    const [openDialog, setOpenDialog] = useState(false);
    const [activeDialog, setActiveDialog] = useState();
    const [activeDialogData, setActiveDialogData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [verifyTokenButtonColor, setVerifyTokenButtonColor] = useState("white");
    const [renewTokenButtonColor, setRenewTokenButtonColor] = useState("white");

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    useEffect(() => {
        let newDialog = []
        activeDialogData.forEach(item => {
            if (item["type"] === "ConfirmDeleteCompany") {
                newDialog.push(
                    <Dialog open={openDialog}
                            handler={handleOpenDialog}>
                        <DialogHeader className="grid place-items-center gap-4">
                            <Typography variant="h5" color="blue-gray">
                                Waarschuwing
                            </Typography>
                        </DialogHeader>
                        <DialogBody className="grid place-items-center gap-4">
                            <Typography className="text-center font-normal">
                                Weet je zeker dat je
                                bedrijf <strong>{item["name"]}</strong> wil verwijderen?
                                Deze actie kan niet ongedaan gemaakt worden.
                            </Typography>
                        </DialogBody>
                        <DialogFooter className="space-x-2">
                            <Button ripple={false} variant="text" color="blue-gray"
                                    onClick={handleOpenDialog}>
                                annuleren
                            </Button>
                            <Button ripple={false} variant="gradient" color="red" onClick={() => {
                                deleteCompany(item["deleteId"])
                                handleOpenDialog()
                            }}>
                                verwijderen
                            </Button>
                        </DialogFooter>
                    </Dialog>
                )
            }
            if (item["type"] === "ConfirmDeleteUser") {
                newDialog.push(
                    <Dialog open={openDialog}
                            handler={handleOpenDialog}>
                        <DialogHeader className="grid place-items-center gap-4">
                            <Typography variant="h5" color="blue-gray">
                                Waarschuwing
                            </Typography>
                        </DialogHeader>
                        <DialogBody className="grid place-items-center gap-4">
                            <Typography className="text-center font-normal">
                                Gebruiker <strong>{item["name"]}</strong> kan alleen verwijderd worden door de system
                                administrator. Als u op doorgaan klikt wordt er een verzoek naar de administrator
                                gestuurt.
                            </Typography>
                        </DialogBody>
                        <DialogFooter className="space-x-2">
                            <Button ripple={false} variant="text" color="blue-gray"
                                    onClick={handleOpenDialog}>
                                annuleren
                            </Button>
                            <Button ripple={false} variant="gradient" color="red" onClick={() => {
                                requestDeleteUser(item["deleteUuid"])
                                handleOpenDialog()
                            }}>
                                doorgaan
                            </Button>
                        </DialogFooter>
                    </Dialog>
                )
            }
            if (item["type"] === "EditCompanyData") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h4" color="blue-gray">
                                    Bedrijf bewerken
                                </Typography>
                                <Input defaultValue={bedrijfsnaamField} label="Bedrijfsnaam"
                                       onChange={(e) => setBedrijfsnaamField(e.target.value)} size="lg"/>
                            </CardBody>
                            <CardFooter className="pt-0">
                                <Button ripple={false} onClick={(e) => {
                                    editCompany({id: item["id"]});
                                    handleOpenDialog()
                                }} fullWidth>
                                    Opslaan
                                </Button>
                            </CardFooter>
                        </Card>
                    </Dialog>
                )
            }
            if (item["type"] === "EditUserData") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h4" color="blue-gray">
                                    Gebruiker bewerken
                                </Typography>
                                <Input label="Voornaam"
                                       defaultValue={firstnameField}
                                       onChange={(e) => setFirstnameField(e.target.value)} size="lg"/>
                                <Input label="Achternaam"
                                       defaultValue={lastnameField}
                                       onChange={(e) => setLastnameField(e.target.value)} size="lg"/>
                                <Input type="email" label="Email"
                                       defaultValue={emailField}
                                       disabled={true}
                                       onChange={(e) => setEmailField(e.target.value)} size="lg"/>
                                <Select label="Bedrijf" value={companySelectorField}
                                        onChange={(e) => setCompanySelectorField(e)}>
                                    {bedrijven.map(({id, name}, index) => {
                                        return (
                                            <Option key={id} value={id}>{name}</Option>
                                        )
                                    })}
                                </Select>
                                {(() => {
                                    if (item["uuid"] === auth.currentUser.uid) {
                                        return (
                                            <Select disabled={true} value={roleSelectorField} label="Rol"
                                                    onChange={(e) => setRoleSelectorField(e)}>
                                                <Option value="user">Gebruiker</Option>
                                                <Option value="companyadmin">Bedrijfsadministrator</Option>
                                                <Option value="admin">Administrator</Option>
                                            </Select>
                                        )
                                    } else {
                                        return (
                                            <Select value={roleSelectorField} label="Rol"
                                                    onChange={(e) => setRoleSelectorField(e)}>
                                                <Option value="user">Gebruiker</Option>
                                                <Option value="companyadmin">Bedrijfsadministrator</Option>
                                                <Option value="admin">Administrator</Option>
                                            </Select>
                                        )
                                    }
                                })()}
                            </CardBody>
                            <CardFooter className="pt-0">
                                <Button
                                    ripple={false}
                                    onClick={(e) => {
                                        editUser({uuid: item["uuid"]});
                                    }} fullWidth>
                                    Opslaan
                                </Button>
                            </CardFooter>
                        </Card>
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={showAlert}
                                icon={<AlertIcon/>}
                                action={
                                    <Button
                                        ripple={false}
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setShowAlert(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {alertMessage}
                            </Alert>
                        </div>
                    </Dialog>
                )
            }
            if (item["type"] === "AddCompany") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h4" color="blue-gray">
                                    Bedrijf toevoegen
                                </Typography>
                                <Input label="Bedrijfsnaam"
                                       onChange={(e) => setBedrijfsnaamField(e.target.value)} size="lg"/>
                            </CardBody>
                            <CardFooter className="pt-0">
                                <Button ripple={false} onClick={(e) => {
                                    addCompany(e);
                                    handleOpenDialog()
                                }} fullWidth>
                                    Toevoegen
                                </Button>
                            </CardFooter>
                        </Card>
                    </Dialog>
                )
            }
            if (item["type"] === "AddUser") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h4" color="blue-gray">
                                    Gebruiker toevoegen
                                </Typography>
                                <Input label="Voornaam"
                                       onChange={(e) => setFirstnameField(e.target.value)} size="lg"/>
                                <Input label="Achternaam"
                                       onChange={(e) => setLastnameField(e.target.value)} size="lg"/>
                                <Input type="email" label="Email"
                                       onChange={(e) => setEmailField(e.target.value)} size="lg"/>
                                <Select label="Bedrijf" onChange={(e) => setCompanySelectorField(e)}>
                                    {bedrijven.map(({id, name}, index) => {
                                        return (
                                            <Option key={id} value={id}>{name}</Option>
                                        )
                                    })}
                                </Select>
                                <Select value="user" label="Rol" onChange={(e) => setRoleSelectorField(e)}>
                                    <Option value="user">Gebruiker</Option>
                                    <Option value="companyadmin">Bedrijfsadministrator</Option>
                                    <Option value="admin">Administrator</Option>
                                </Select>
                            </CardBody>
                            <CardFooter className="pt-0">
                                <Button
                                    ripple={false}
                                    onClick={(e) => {
                                        addUser(e);
                                    }} fullWidth>
                                    Toevoegen
                                </Button>
                            </CardFooter>
                        </Card>
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={showAlert}
                                icon={<AlertIcon/>}
                                action={
                                    <Button
                                        ripple={false}
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setShowAlert(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {alertMessage}
                            </Alert>
                        </div>
                    </Dialog>
                )
            }
            if (item["type"] === "SendMail") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h4" color="blue-gray">
                                    Send mail
                                </Typography>
                                <Select value="TestEmail" label="Template"
                                        onChange={(e) => setTemplateSelectorField(e)}>
                                    <Option value="TestEmail">Test email</Option>
                                </Select>
                                <Input label="Voornaam ontvanger"
                                       onChange={(e) => setFirstnameField(e.target.value)} size="lg"/>
                                <Input type="email" label="Email"
                                       onChange={(e) => setEmailField(e.target.value)} size="lg"/>
                            </CardBody>
                            <CardFooter className="pt-0">
                                <Button
                                    loading={isLoading}
                                    ripple={false}
                                    onClick={(e) => {
                                        sendMail(e);
                                    }} fullWidth>
                                    Call
                                </Button>
                            </CardFooter>
                        </Card>
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={showAlert}
                                icon={<AlertIcon/>}
                                action={
                                    <Button
                                        ripple={false}
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setShowAlert(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {alertMessage}
                            </Alert>
                        </div>
                    </Dialog>
                )
            }
        })

        setActiveDialog(newDialog[0])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, activeDialogData, openDialog, bedrijfsnaamField, emailField, firstnameField, lastnameField, companySelectorField, roleSelectorField, showAlert, templateSelectorField])

    useEffect(() => {
        if (selected !== lastselected || lastselected === 0) {
            if (selected === 1) {
                navigate(`../gebruikers`)
            }

            if (selected === 2) {
                navigate(`../bedrijven`)
            }

            if (selected === 99) {
                navigate(`../devtools`)
            }

            setLastSelected(selected)
            setListData(
                <List>
                    <ListItem
                        ripple={false}
                        selected={selected === 1}
                        onClick={() => setSelected(1)}
                    >
                        <ListItemPrefix>
                            {(() => {
                                if (selected === 1) {
                                    return <UserGroupIconSolid className="h-5 w-5"/>
                                } else {
                                    return <UserGroupIconOutline className="h-5 w-5"/>
                                }
                            })()}
                        </ListItemPrefix>
                        Gebruikers
                    </ListItem>
                    <ListItem
                        ripple={false}
                        selected={selected === 2}
                        onClick={() => setSelected(2)}
                    >
                        <ListItemPrefix>
                            {(() => {
                                if (selected === 2) {
                                    return <BuildingOffice2IconSolid className="h-5 w-5"/>
                                } else {
                                    return <BuildingOffice2IconOutline className="h-5 w-5"/>
                                }
                            })()}
                        </ListItemPrefix>
                        Bedrijven
                    </ListItem>
                    <ListItem
                        ripple={false}
                        selected={selected === 99}
                        onClick={() => setSelected(99)}
                    >
                        <ListItemPrefix>
                            {(() => {
                                if (selected === 99) {
                                    return <WrenchScrewdriverIconSolid className="h-5 w-5"/>
                                } else {
                                    return <WrenchScrewdriverIconOutline className="h-5 w-5"/>
                                }
                            })()}
                        </ListItemPrefix>
                        Dev Tools
                    </ListItem>
                </List>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        if (selected === 0) {
            setViewData(welcome)
        }

        if (selected === 1) {
            const handleOpenAddUserDialog = async () => {
                setActiveDialogData([{
                    type: "AddUser",
                }])

                setFirstnameField('')
                setLastnameField('')
                setEmailField('')
                setRoleSelectorField('user')
                setCompanySelectorField()

                setOpenDialog(!openDialog)
            }

            const handleOpenEditUserDataDialog = async (firstname, lastname, email, role, company, uuid) => {
                setActiveDialogData([{
                    type: "EditUserData",
                    uuid: uuid
                }])

                setFirstnameField(firstname)
                setLastnameField(lastname)
                setEmailField(email)
                setRoleSelectorField(role)
                setCompanySelectorField(company)

                setOpenDialog(!openDialog)
            }

            const handleOpenConfirmDeleteDialog = async (firstname, lastname, uuid) => {
                setActiveDialogData([{
                    type: "ConfirmDeleteUser",
                    name: firstname + " " + lastname,
                    deleteUuid: uuid
                }])
                setOpenDialog(!openDialog)
            }

            const GEBRUIKERS_TABLE_HEAD = ["Naam", "Email", "Bedrijf", "Rol", ""];

            setViewData(
                <div>
                    <div className="flex p-6">
                        <div className="grow mr-4">
                            <p className="text-left text-3xl text-black">Gebruikers</p>
                        </div>
                        <div className="grid justify-items-end">
                            <div className="flex items-center gap-4">
                                <Button
                                    variant="outlined"
                                    size="sm"
                                    ripple={false}
                                    onClick={handleOpenAddUserDialog}
                                    className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                >
                                    <FontAwesomeIcon icon={faUserPlus}/><span
                                    className="ml-3">Gebruiker toevoegen</span>
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="px-6">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                            <tr>
                                {GEBRUIKERS_TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {gebruikers.map(({
                                                 firstname,
                                                 lastname,
                                                 email,
                                                 role,
                                                 gbiid,
                                                 uuid,
                                                 requestedDeletion
                                             }, index) => {
                                const isLast = index === bedrijven.length - 1;
                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={uuid} className="even:bg-blue-gray-50/50">
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {firstname} {lastname}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {email}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {bedrijven.filter(item => item.id === gbiid).map(item => item.name)}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {(() => {
                                                    let roleString

                                                    if (role === "user") {
                                                        roleString = "Gebruiker"
                                                    }

                                                    if (role === "companyadmin") {
                                                        roleString = "Bedrijfsadministrator"
                                                    }

                                                    if (role === "admin") {
                                                        roleString = "Administrator"
                                                    }

                                                    return roleString
                                                })()}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div>
                                                {(() => {
                                                    if (requestedDeletion === true) {
                                                        return (
                                                            <div className="flex justify-end gap-2">
                                                                <Tooltip
                                                                    content="Verwijdering van deze gebruiker is in behandeling">
                                                                    <div className="flex gap-2">
                                                                        <IconButton disabled={true} size="sm"
                                                                                    variant="text"
                                                                                    ripple={false}>
                                                                            <FontAwesomeIcon icon={faPencil}/>
                                                                        </IconButton>
                                                                        <IconButton size="sm" variant="text"
                                                                                    color="red"
                                                                                    ripple={false}
                                                                                    disabled={true}>
                                                                            <FontAwesomeIcon icon={faPersonDigging}/>
                                                                        </IconButton>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="flex justify-end gap-2">
                                                                <Tooltip content="Bewerken">
                                                                    <IconButton size="sm" variant="text" ripple={false}
                                                                                onClick={() => handleOpenEditUserDataDialog(firstname, lastname, email, role, gbiid, uuid)}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    content="Verwijderen">
                                                                    <span>
                                                                        <IconButton size="sm"
                                                                                    variant="text"
                                                                                    color="red"
                                                                                    ripple={false}
                                                                                    onClick={() => handleOpenConfirmDeleteDialog(firstname, lastname, uuid)}>
                                                                            <FontAwesomeIcon icon={faTrashCan}/>
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

        if (selected === 2) {
            const handleOpenConfirmDeleteDialog = async (name, id) => {
                setActiveDialogData([{
                    type: "ConfirmDeleteCompany",
                    name: name,
                    deleteId: id
                }])
                setOpenDialog(!openDialog)
            }

            const handleOpenAddCompanyDialog = async () => {
                setActiveDialogData([{
                    type: "AddCompany"
                }])
                setBedrijfsnaamField('')
                setOpenDialog(!openDialog)
            }

            const handleOpenEditCompanyDataDialog = async (name, id) => {
                setActiveDialogData([{
                    type: "EditCompanyData",
                    name: name,
                    id: id
                }])
                setBedrijfsnaamField(name)
                setOpenDialog(!openDialog)
            }

            const BEDRIJVEN_TABLE_HEAD = ["ID", "Bedrijfsnaam", ""];

            setViewData(
                <div>
                    <div className="flex p-6">
                        <div className="grow mr-4">
                            <p className="text-left text-3xl text-black">Bedrijven</p>
                        </div>
                        <div className="grid justify-items-end">
                            <div className="flex items-center gap-4">
                                <Button
                                    variant="outlined"
                                    size="sm"
                                    ripple={false}
                                    onClick={handleOpenAddCompanyDialog}
                                    className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                >
                                    <FontAwesomeIcon icon={faPlus}/><span
                                    className="ml-3">Bedrijf toevoegen</span>
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="px-6">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                            <tr>
                                {BEDRIJVEN_TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {bedrijven.map(({id, name}, index) => {
                                const isLast = index === bedrijven.length - 1;
                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={id} className="even:bg-blue-gray-50/50">
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {id}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {name}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex justify-end gap-2">
                                                <Tooltip content="Bewerken">
                                                    <IconButton size="sm" variant="text" ripple={false}
                                                                onClick={() => handleOpenEditCompanyDataDialog(name, id)}>
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </IconButton>
                                                </Tooltip>
                                                {(() => {
                                                    let count = 0
                                                    gebruikers.forEach(item => {
                                                        if (item["gbiid"] === id) {
                                                            count += 1
                                                        }
                                                    })

                                                    if (count === 0) {
                                                        return (
                                                            <Tooltip content="Verwijderen">
                                                                    <span>
                                                                        <IconButton size="sm" variant="text"
                                                                                    color="red"
                                                                                    ripple={false}
                                                                                    onClick={() => handleOpenConfirmDeleteDialog(name, id)}>
                                                                            <FontAwesomeIcon icon={faTrashCan}/>
                                                                        </IconButton>
                                                                    </span>
                                                            </Tooltip>
                                                        )
                                                    } else {
                                                        return (
                                                            <Tooltip
                                                                content="Er zijn gebruikers gekoppeld aan dit bedrijf, je kunt deze nu niet verwijderen">
                                                                    <span>
                                                                        <IconButton disabled={true} size="sm"
                                                                                    variant="text"
                                                                                    color="red"
                                                                                    ripple={false}>
                                                                            <FontAwesomeIcon icon={faTrashCan}/>
                                                                        </IconButton>
                                                                    </span>
                                                            </Tooltip>
                                                        )
                                                    }


                                                })()}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

        if (selected === 99) {
            const handleOpenSendMailDialog = async () => {
                setActiveDialogData([{
                    type: "SendMail"
                }])
                setTemplateSelectorField("TestEmail")
                setOpenDialog(!openDialog)
            }

            const verifyTokenWithButtonColor = () => {
                Token.verify().then((response) => {
                    response === "OK" ? setVerifyTokenButtonColor("green") : setVerifyTokenButtonColor("red")
                    wait(500).then(() => {setVerifyTokenButtonColor("white")})
                })
            }

            const renewTokenWithButtonColor = () => {
                Token.renew().then((response) => {
                    response === "OK" ? setRenewTokenButtonColor("green") : setRenewTokenButtonColor("red")
                    wait(500).then(() => {setRenewTokenButtonColor("white")})
                })
            }

            setViewData(
                <div>
                    <div className="flex p-6">
                        <div className="grow mr-4">
                            <p className="text-left text-3xl text-black">Dev Tools</p>
                        </div>
                    </div>

                    <div className="w-full m-auto px-6 grid grid-cols-4 gap-4">
                        <div>
                            <Card color="gray" variant="gradient" className="w-full p-8">
                                <CardHeader
                                    floated={false}
                                    shadow={false}
                                    color="transparent"
                                    className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
                                >
                                    <Typography
                                        variant="h4"
                                        color="white"
                                        className="font-normal"
                                    >
                                        Token API Calls
                                    </Typography>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <ul className="flex flex-col gap-4">
                                        <li className="flex items-center gap-4">
                                            <Button className="w-full" size="sm" color="white" variant="gradient"
                                                    onClick={Token.create}
                                                    ripple={false}>Create token</Button>
                                        </li>
                                        <li>
                                            <Button className="w-full" size="sm" color={verifyTokenButtonColor} variant="gradient"
                                                    onClick={verifyTokenWithButtonColor}
                                                    ripple={false}>Verify token</Button>
                                        </li>
                                        <li>
                                            <Button className="w-full" size="sm" color={renewTokenButtonColor} variant="gradient"
                                                    onClick={renewTokenWithButtonColor}
                                                    ripple={false}>Renew token</Button>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>
                        <div>
                            <Card color="gray" variant="gradient" className="w-full p-8">
                                <CardHeader
                                    floated={false}
                                    shadow={false}
                                    color="transparent"
                                    className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
                                >
                                    <Typography
                                        variant="h4"
                                        color="white"
                                        className="font-normal"
                                    >
                                        Mail API Calls
                                    </Typography>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <ul className="flex flex-col gap-4">
                                        <li className="flex items-center gap-4">
                                            <Button className="w-full" size="sm" color="white" variant="gradient"
                                                    onClick={handleOpenSendMailDialog}
                                                    ripple={false}>Send mail</Button>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, bedrijven, gebruikers, openDialog, verifyTokenButtonColor, renewTokenButtonColor])

    return (
        <div className="h-full relative">
            <div
                className="2xl:hidden m-[20px] duration-100 shadow-none z-50 fixed bottom-[50%] left-[-30px]">
                <div className=""></div>
                <Button variant="outlined"
                        ripple={false}
                        className="duration-100 hover:shadow-2xl w-[10px] hover:w-[50px]" size="sm"
                        onClick={openDrawer}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </Button>
            </div>

            <Drawer open={openSideDrawer} onClose={closeDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between">
                    <Typography variant="h5" color="blue-gray">
                    </Typography>
                    <IconButton variant="text" color="blue-gray" ripple={false} onClick={closeDrawer}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>
                {listData}
            </Drawer>

            <div>
                {activeDialog}
            </div>

            <div className="hidden 2xl:block h-full">
                <div className="flex w-full h-full">

                    <div className="flex-none">
                        <div className="m-[20px] w-[300px]">
                            <Card className="h-auto w-full p-4 shadow-lg sticky top-[90px]">
                                <div>
                                    {listData}
                                </div>
                            </Card>
                        </div>
                    </div>

                    <div className="grow overflow-x-hidden">
                        <div className="p-[20px] h-full">
                            <div className="h-full">
                                <Card className="h-full p-4 shadow-lg">
                                    {viewData}
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="2xl:hidden h-full p-[20px]">
                <div className="h-full">
                    <Card className="h-full w-full p-4 shadow-lg">
                        {viewData}
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Admin;
