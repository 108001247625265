import {Route, Routes} from "react-router-dom";
import LayoutLoggedin from "./pages/Layout-Loggedin";
import React from "react";
import Dashboard from "./pages/Dashboard";

const DashboardApp = () => {
    return (
        <Routes>
            <Route path="/" element={<LayoutLoggedin/>}>
                <Route index element={<Dashboard/>}/>
                <Route path=":rnum" element={<Dashboard/>}>
                    <Route path=":vpagestring" element={<Dashboard/>}>
                        <Route path="*" element={<Dashboard/>}/>
                    </Route>
                </Route>
            </Route>
        </Routes>
    )
}

export default DashboardApp