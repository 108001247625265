import { createSlice } from '@reduxjs/toolkit'

export const editModeSlice = createSlice({
    name: 'editMode',
    initialState: {
        value: false,
    },
    reducers: {
        toggle: (state) => {
            state.value = !state.value
        },
        setValue: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { toggle, setValue } = editModeSlice.actions

export default editModeSlice.reducer