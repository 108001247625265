import axios from 'axios'
import { auth } from "./firebase"
import {signOut} from "firebase/auth"
import axiosRetry from "axios-retry"

const baseurl = "https://dashboard.gbivanbeijeren.nl"

// Automatic token renew
// When token fails to renew, force user to login again to request new token (token might be very old or stolen, so cannot create new one)
axiosRetry(axios, {
    retries: 3, // Number of retries
    retryCondition(error) {
        // Conditional check the error status code
        switch (error.response.status) {
            case 481:
                Token.renew()
                return true
            case 482:
                signOut(auth)
                return false
            case 492:
                signOut(auth)
                return false
            default:
                return false
        }
    },
})

const Token = {
    create: async function() {
        let returnVal = undefined

        const user = auth.currentUser

        if (user) {
            await axios.get(`${baseurl}/api/token/create`,{withCredentials: true, headers: {"uid": user.uid}})
                .then(function (response) {
                    if (response.status === 200) {
                        returnVal = "OK"
                    }
                })
                .catch(function (error) {
                    returnVal = error
                });
        }

        return returnVal
    },
    verify: async function() {
        let returnVal = undefined

        const user = auth.currentUser

        if (user) {
            await axios.get(`${baseurl}/api/token/verify`,{ withCredentials: true })
                .then(function (response) {
                    if (response.status === 200) {
                        returnVal = "OK"
                    }
                })
                .catch(function (error) {
                    returnVal = error
                });
        }

        return returnVal
    },
    renew: async function() {
        let returnVal = undefined

        const user = auth.currentUser

        if (user) {
            await axios.get(`${baseurl}/api/token/renew`,{ withCredentials: true })
                .then(function (response) {
                    if (response.status === 200) {
                        returnVal = "OK"
                    }
                })
                .catch(function (error) {
                    returnVal = error
                });
        }

        return returnVal
    }
}

const Mail = {
    send: async function(template, data) {
        let returnVal = undefined
        await axios.post(`${baseurl}/api/mail/send`, {
            template: template,
            data: data,
        }, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    }
}

const Data = {
    clients: async function() {
        let returnVal = undefined
        await axios.get(`${baseurl}/api/data/clients`, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    },
    clientinfo: async function(relatienummer) {
        let returnVal = undefined
        await axios.get(`${baseurl}/api/data/clientinfo?relatienummer=${relatienummer}`, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    },
    clientcontacts: async function(relatienummer) {
        let returnVal = undefined
        await axios.get(`${baseurl}/api/data/clientcontacts?relatienummer=${relatienummer}`, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    },
    salesperquarter_options: async function() {
        let returnVal = undefined
        await axios.get(`${baseurl}/api/data/salesperquarter/options`, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                } else if (response.status === 404) {
                    returnVal = {isError: true}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    },
    salesperquarter: async function(relatienummer, quarter, year) {
        let returnVal = undefined
        await axios.get(`${baseurl}/api/data/salesperquarter?relatienummer=${relatienummer}&quarter=${quarter}&year=${year}`, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                } else if (response.status === 404) {
                    returnVal = {isError: true}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    },
    salesfromlastfiveyears: async function(relatienummer) {
        let returnVal = undefined
        await axios.get(`${baseurl}/api/data/salesfromlastfiveyears?relatienummer=${relatienummer}`, { withCredentials: true })
            .then(function (response) {
                if (response.status === 200) {
                    returnVal = {isError: false, response: response}
                } else if (response.status === 404) {
                    returnVal = {isError: true}
                }
            })
            .catch(function (error) {
                returnVal = {isError: true, response: error}
            });
        return returnVal
    }
}

export { Token, Mail, Data }