import '../output.css';
import React, {useEffect, useRef, useState} from "react";
import {useParams, useNavigate, useOutletContext} from 'react-router-dom';
import Chart from "react-apexcharts";

import {
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Input,
    Typography,
    Select,
    Option,
    Button,
    Drawer,
    IconButton,
    ListItemSuffix,
    Spinner,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    CardBody,
    CardFooter,
    Alert,
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel, Textarea,
} from "@material-tailwind/react";

// Solid icons
import IdentificationIconSolid from "@heroicons/react/24/solid/IdentificationIcon"
import PhoneIconSolid from "@heroicons/react/24/solid/PhoneIcon"
import MinusIconSolid from "@heroicons/react/24/solid/MinusIcon"
import ChartBarIconSolid from "@heroicons/react/24/solid/ChartBarIcon"

// Outline icons
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import IdentificationIconOutline from "@heroicons/react/24/outline/IdentificationIcon"
import PhoneIconOutline from "@heroicons/react/24/outline/PhoneIcon"
import QuestionMarkCicleIconOutline from "@heroicons/react/24/outline/QuestionMarkCircleIcon"
import ChartBarIconOutline from "@heroicons/react/24/outline/ChartBarIcon"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe,
    faArrowUpRightFromSquare,
    faAngleRight,
    faEuroSign,
    faPencil,
    faFileExport
} from "@fortawesome/free-solid-svg-icons";
import {Data, Mail} from "../apiHandler";
import {useSelector} from "react-redux";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
import {TableExport} from "tableexport";
import NewComponent from "../components/NewComponent";

function Dashboard() {
    let navigate = useNavigate();

    const rangetclients = useRef(false)
    const rangetclientinfo = useRef(false)
    const rangetclientcontacts = useRef(false)
    const rangetchart2data = useRef(false)
    const rangetchart1options = useRef(false)

    const {rnum} = useParams()
    const {vpagestring} = useParams()

    const [gebruikers, setGebruikers] = useState([])
    const {currentUserData} = useOutletContext()

    function AlertIcon() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-6 w-6"
            >
                <path
                    fillRule="evenodd"
                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                />
            </svg>
        );
    }

    async function getUsers() {
        let gebruikersCache = []
        const gebruikersCol = collection(db, "users");
        const gebruikersSnapshot = await getDocs(gebruikersCol);
        gebruikersSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            gebruikersCache.push(
                {
                    uuid: doc.data()["uuid"],
                    firstname: doc.data()["name"]["first"],
                    lastname: doc.data()["name"]["last"],
                    email: doc.data()["email"],
                    role: doc.data()["role"],
                    gbiid: doc.data()["gbiid"],
                    requestedDeletion: doc.data()["requestedDeletion"]
                }
            )
        });

        console.log(gebruikersCache)
        setGebruikers(gebruikersCache)
    }

    const getclients = async () => {
        if (rangetclients.current === false) {
            const response = await Data.clients()

            if (response["isError"] === false) {
                setClients(response["response"].data)
                rangetclients.current = true
            } else {
                console.log('error ' + response["response"]);
            }
        }
    }

    const getclientinfo = async (relatienummer) => {
        if (rangetclientinfo.current === false) {
            const response = await Data.clientinfo(relatienummer)

            if (response["isError"] === false) {
                setClientinfo(response["response"].data)
                rangetclientinfo.current = true
            } else {
                console.log('error ' + response["response"]);
            }
        }
    }

    const getclientcontacts = async (relatienummer) => {
        if (rangetclientcontacts.current === false) {
            const response = await Data.clientcontacts(relatienummer)

            if (response["isError"] === false) {
                setClientcontacts(response["response"].data)
                rangetclientcontacts.current = true
            } else {
                console.log('error ' + response["response"]);
            }
        }
    }

    const getchart1options = async () => {
        if (rangetchart1options.current === false) {
            setChart1YearSelectLoading(true)
            setChart1QuarterSelectLoading(true)

            const response = await Data.salesperquarter_options()

            if (response["isError"] === false) {
                rangetchart1options.current = true
                setChart1Options(response["response"]["data"])
                setChart1YearSelectLoading(false)
            }
        }
    }

    const getchart1data = async (relatienummer, quarter, year) => {
        let salesperquarter = {}

        setChart1Loading(true)

        const response1 = await Data.salesperquarter(relatienummer, quarter, year)
        const response2 = await Data.salesperquarter(relatienummer, quarter, (year - 1))

        if (response1["isError"] === false && response2["isError"] === false) {
            salesperquarter[year] = {}
            salesperquarter[year - 1] = {}
            salesperquarter[year][quarter] = response1["response"]["data"]
            salesperquarter[year - 1][quarter] = response2["response"]["data"]

            setChart1Data(salesperquarter)
            setChart1Loading(false)
        }
    }

    const getchart2data = async (relatienummer) => {
        setChart2Loading(true)

        const response = await Data.salesfromlastfiveyears(relatienummer)

        if (response["isError"] === false) {
            setChart2Data(response["response"]["data"])
            setChart2Loading(false)
            rangetchart2data.current = true
        }
    }

    const sendMail = async (template, data) => {
        if ((userSelector !== "") && (opmerking !== "" || newValue !== "")) {
            setIsMailButtonLoading(true)

            let mailData = data["mailData"]

            let results = []
            const q = query(collection(db, "users"), where("uuid", "==", data["selectedUserUUID"]))
            const querySnapshot = await getDocs(q)
            if (!querySnapshot.empty) {
                querySnapshot.forEach(doc => {
                    results.push(doc.data())
                })
            }
            if (results.length > 0) {
                mailData["recipientEmail"] = results[0]["email"]
                mailData["recipientFirstname"] = results[0]["name"]["first"]

                const result = await Mail.send(template, mailData)

                if (result["isError"] === false) {
                    handleOpenDialog()
                    setIsMailButtonLoading(false)
                } else {
                    setIsMailButtonLoading(false)
                    setAlertMessage(`Kon mail niet versturen. (${result["response"]})`)
                    setShowAlert(true)
                }
            } else {
                setIsMailButtonLoading(false)
                setAlertMessage(`Kon mail niet versturen.`)
                setShowAlert(true)
            }

        } else {
            setAlertMessage("Kon mail niet versturen.")
            setShowAlert(true)
        }
    }

    useEffect(() => {
        if (rnum !== undefined) {
            if (vpagestring !== undefined) {
                let pagina
                if (vpagestring === "klantinformatie") {
                    pagina = "Klantinformatie"
                }
                if (vpagestring === "contactpersonen") {
                    pagina = "Contactpersonen"
                }
                if (vpagestring === "statistieken") {
                    pagina = "Statistieken"
                }
                document.title = `GBI Dashboard | ${pagina} - Relatie ${rnum}`
            } else {
                document.title = `GBI Dashboard | Relatie ${rnum}`
            }
        } else {
            document.title = `GBI Dashboard | Welkom`
        }
    })

    useEffect(() => {
        if (gebruikers.length === 0) {
            getUsers()
        }

        if (rangetclients.current === false) {
            getclients()
        }

        if (rnum !== undefined) {
            if (rangetclients.current === false) {
                getclientinfo(rnum)
            }

            if (rangetclientcontacts.current === false) {
                getclientcontacts(rnum)
            }

            if (rangetchart1options.current === false) {
                getchart1options()
            }

            if (rangetchart2data.current === false) {
                getchart2data(rnum)
            }
        }
    }, [rnum])

    const welcome = (
        <div className="text-center m-auto">
            <p className="text-5xl p-6">Welkom</p>
            <p>Selecteer een item in de zijbalk om te beginnen</p>
        </div>
    )

    const selecteerjaar = (
        <div className="text-center m-auto">
            <p>Selecteer een jaar om top 10 artikelen op te halen</p>
        </div>
    )

    let vpage
    if (vpagestring === "klantinformatie") {
        vpage = 1
    } else if (vpagestring === "contactpersonen") {
        vpage = 2
    } else if (vpagestring === "statistieken") {
        vpage = 3
    } else {
        vpage = 0
    }

    const [clients, setClients] = useState({});
    const [clientinfo, setClientinfo] = useState({});
    const [clientcontacts, setClientcontacts] = useState({});

    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const openDrawer = () => setOpenSideDrawer(true);
    const closeDrawer = () => setOpenSideDrawer(false);

    const [selected, setSelected] = useState(vpage);

    const [listData, setListData] = useState()
    const [viewData, setViewData] = useState(welcome)
    const [contactTablePages, setContactTablePages] = useState(0)
    const [contactTablePage, setContactTablePage] = useState(0)
    const [relatieNum, setRelatieNum] = useState((rnum !== undefined) ? rnum : undefined)
    const [companySearch, setCompanySearch] = useState(undefined)
    const [companySearchField, setCompanySearchField] = useState(undefined)
    const [lastselected, setLastSelected] = useState(0)

    // Chart 1 - Omzet per productcategorie
    const [chart1Options, setChart1Options] = useState({})
    const [chart1Loading, setChart1Loading] = useState(true)
    const [chart1YearSelectLoading, setChart1YearSelectLoading] = useState(true)
    const [chart1QuarterSelectLoading, setChart1QuarterSelectLoading] = useState(true)
    const [chart1Data, setChart1Data] = useState({})
    const [chart1Year, setChart1Year] = useState()
    const [previousChart1Year, setPreviousChart1Year] = useState()
    const [chart1Quarter, setChart1Quarter] = useState()

    // Chart 2- Omzet van de afgelopen 5 jaar
    const [chart2Loading, setChart2Loading] = useState(true)
    const [chart2Data, setChart2Data] = useState({})

    const [quarters, setQuarters] = useState()

    // Edit mode
    const editMode = useSelector((state) => state.editMode.value)
    const [newValue, setNewValue] = useState()
    const [opmerking, setOpmerking] = useState()
    const [userSelector, setUserSelector] = useState()

    const [newContactpersoonAfdeling, setNewContactpersoonAfdeling] = useState()
    const [newContactpersoonFunctie, setNewContactpersoonFunctie] = useState()
    const [newContactpersoonTelefoon, setNewContactpersoonTelefoon] = useState()
    const [newContactpersoonMobiel, setNewContactpersoonMobiel] = useState()
    const [newContactpersoonEmail, setNewContactpersoonEmail] = useState()
    const [newContactpersoonOpmerking, setNewContactpersoonOpmerking] = useState()

    // Dialog fields and data
    const [openDialog, setOpenDialog] = useState(false);
    const [activeDialog, setActiveDialog] = useState();
    const [activeDialogData, setActiveDialogData] = useState([]);
    const [isMailButtonLoading, setIsMailButtonLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    useEffect(() => {
        setChart1Loading(true)
        getchart1data(rnum, chart1Quarter, chart1Year)
    }, [chart1Quarter])

    useEffect(() => {
        setChart1Loading(true)
        setChart1QuarterSelectLoading(true)
        getchart1data(rnum, chart1Quarter, chart1Year)
    }, [chart1Year])

    useEffect(() => {
        if (chart1Year !== previousChart1Year && chart1Year !== undefined) {
            if (chart1QuarterSelectLoading === true) {
                setQuarters(chart1Options[chart1Year])
                setChart1QuarterSelectLoading(false)
            }
        }
    }, [chart1Loading, chart1Year, chart1Options, chart1QuarterSelectLoading])

    useEffect(() => {
        let newDialog = []
        activeDialogData.forEach(item => {
            if (item["type"] === "EditMode") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h5" color="blue-gray">
                                    {item["field"]} bewerken
                                </Typography>
                                <Select label="Naar gebruiker" onChange={(e) => setUserSelector(e)}>
                                    {gebruikers.map(({uuid, firstname, lastname, email}, index) => {
                                        return (
                                            <Option key={uuid}
                                                    value={uuid}>{firstname} {lastname} ({email})</Option>
                                        )
                                    })}
                                </Select>
                                <Tabs value="wijziging">
                                    <TabsHeader>
                                        <Tab key="wijziging" value="wijziging">
                                            Wijziging
                                        </Tab>
                                        <Tab key="opmerking" value="opmerking">
                                            Opmerking
                                        </Tab>
                                    </TabsHeader>
                                    <TabsBody>
                                        <TabPanel key="wijziging" value="wijziging" className="p-0">
                                            <div className="mt-4">
                                                <div className="mt-2">
                                                    <Input defaultValue={newValue} label="Nieuwe waarde"
                                                           onChange={(e) => setNewValue(e.target.value)}/>
                                                </div>

                                                <div className="mt-4">
                                                    <Button loading={isMailButtonLoading} ripple={false}
                                                            onClick={(e) => {
                                                                sendMail("FieldAmendment", {
                                                                    mailData: {
                                                                        senderFirstname: currentUserData["name"]["first"],
                                                                        senderLastname: currentUserData["name"]["last"],
                                                                        fieldName: item["field"],
                                                                        pageName: item["page"],
                                                                        oldValue: item["oldValue"],
                                                                        newValue: newValue,
                                                                        relatieNummer: item["relatieNummer"],
                                                                        relatieNaam: item["relatieNaam"]
                                                                    }, selectedUserUUID: userSelector
                                                                })
                                                            }} fullWidth>
                                                        Wijziging versturen
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel key="opmerking" value="opmerking" className="p-0">
                                            <div className="mt-6">
                                                <Textarea label="Opmerking" defaultValue={opmerking}
                                                          onChange={(e) => setOpmerking(e.target.value)}/>
                                                <div className="mt-4">
                                                    <Button loading={isMailButtonLoading} ripple={false}
                                                            onClick={(e) => {
                                                                sendMail("FieldComment", {
                                                                    mailData: {
                                                                        senderFirstname: currentUserData["name"]["first"],
                                                                        senderLastname: currentUserData["name"]["last"],
                                                                        fieldName: item["field"],
                                                                        pageName: item["page"],
                                                                        opmerking: opmerking,
                                                                        relatieNummer: item["relatieNummer"],
                                                                        relatieNaam: item["relatieNaam"]
                                                                    }, selectedUserUUID: userSelector
                                                                })
                                                            }} fullWidth>
                                                        Opmerking versturen
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabsBody>
                                </Tabs>
                            </CardBody>
                        </Card>
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={showAlert}
                                icon={<AlertIcon/>}
                                action={
                                    <Button
                                        ripple={false}
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setShowAlert(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {alertMessage}
                            </Alert>
                        </div>
                    </Dialog>
                )
            }
            if (item["type"] === "OpmerkingenEditMode") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h5" color="blue-gray">
                                    {item["field"]} bewerken
                                </Typography>
                                <Select label="Naar gebruiker" onChange={(e) => setUserSelector(e)}>
                                    {gebruikers.map(({uuid, firstname, lastname, email}, index) => {
                                        return (
                                            <Option key={uuid}
                                                    value={uuid}>{firstname} {lastname} ({email})</Option>
                                        )
                                    })}
                                </Select>
                                <Tabs value="wijziging">
                                    <TabsHeader>
                                        <Tab key="wijziging" value="wijziging">
                                            Wijziging
                                        </Tab>
                                        <Tab key="opmerking" value="opmerking">
                                            Opmerking
                                        </Tab>
                                    </TabsHeader>
                                    <TabsBody>
                                        <TabPanel key="wijziging" value="wijziging" className="p-0">
                                            <div className="mt-4">
                                                <div className="mt-2">
                                                    <Textarea defaultValue={newValue} label="Nieuwe waarde"
                                                              onChange={(e) => setNewValue(e.target.value)}/>
                                                </div>

                                                <div className="mt-4">
                                                    <Button loading={isMailButtonLoading} ripple={false}
                                                            onClick={(e) => {
                                                                sendMail("FieldAmendment", {
                                                                    mailData: {
                                                                        senderFirstname: currentUserData["name"]["first"],
                                                                        senderLastname: currentUserData["name"]["last"],
                                                                        fieldName: item["field"],
                                                                        pageName: item["page"],
                                                                        oldValue: item["oldValue"],
                                                                        newValue: newValue,
                                                                        relatieNummer: item["relatieNummer"],
                                                                        relatieNaam: item["relatieNaam"]
                                                                    }, selectedUserUUID: userSelector
                                                                })
                                                            }} fullWidth>
                                                        Wijziging versturen
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel key="opmerking" value="opmerking" className="p-0">
                                            <div className="mt-6">
                                                <Textarea label="Opmerking" defaultValue={opmerking}
                                                          onChange={(e) => setOpmerking(e.target.value)}/>
                                                <div className="mt-4">
                                                    <Button loading={isMailButtonLoading} ripple={false}
                                                            onClick={(e) => {
                                                                sendMail("FieldComment", {
                                                                    mailData: {
                                                                        senderFirstname: currentUserData["name"]["first"],
                                                                        senderLastname: currentUserData["name"]["last"],
                                                                        fieldName: item["field"],
                                                                        pageName: item["page"],
                                                                        opmerking: opmerking,
                                                                        relatieNummer: item["relatieNummer"],
                                                                        relatieNaam: item["relatieNaam"]
                                                                    }, selectedUserUUID: userSelector
                                                                })
                                                            }} fullWidth>
                                                        Opmerking versturen
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabsBody>
                                </Tabs>
                            </CardBody>
                        </Card>
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={showAlert}
                                icon={<AlertIcon/>}
                                action={
                                    <Button
                                        ripple={false}
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setShowAlert(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {alertMessage}
                            </Alert>
                        </div>
                    </Dialog>
                )
            }

            /*{
                type: "ContactpersonenEditMode",
                    page: "Contactpersonen",
                relatieNummer: relatieNum,
                relatieNaam: clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam,
                contactpersoon: contactpersoon,
                oldContactObject: oldContactObject
            }*/

            if (item["type"] === "ContactpersonenEditMode") {
                newDialog.push(
                    <Dialog
                        size="xs"
                        open={openDialog}
                        handler={handleOpenDialog}
                        className="bg-transparent shadow-none"
                    >
                        <Card className="mx-auto w-full max-w-[24rem]">
                            <CardBody className="flex flex-col gap-4">
                                <Typography variant="h5" color="blue-gray">
                                    {item["contactpersoon"]} bewerken
                                </Typography>
                                <Select label="Naar gebruiker" onChange={(e) => setUserSelector(e)}>
                                    {gebruikers.map(({uuid, firstname, lastname, email}, index) => {
                                        return (
                                            <Option key={uuid}
                                                    value={uuid}>{firstname} {lastname} ({email})</Option>
                                        )
                                    })}
                                </Select>
                                <Tabs value="wijziging">
                                    <TabsHeader>
                                        <Tab key="wijziging" value="wijziging">
                                            Wijziging
                                        </Tab>
                                        <Tab key="opmerking" value="opmerking">
                                            Opmerking
                                        </Tab>
                                    </TabsHeader>
                                    <TabsBody>
                                        <TabPanel key="wijziging" value="wijziging" className="p-0">
                                            <div className="mt-4">
                                                <div className="mt-2">
                                                    <Input defaultValue={item["contactpersoon"]} label="Naam"
                                                           disabled={true}/>
                                                </div>
                                                <div className="mt-2">
                                                    <Input defaultValue={newContactpersoonAfdeling} label="Afdeling"
                                                           onChange={(e) => setNewContactpersoonAfdeling(e.target.value)}/>
                                                </div>
                                                <div className="mt-2">
                                                    <Input defaultValue={newContactpersoonFunctie} label="Functie"
                                                           onChange={(e) => setNewContactpersoonFunctie(e.target.value)}/>
                                                </div>
                                                <div className="mt-2">
                                                    <Input defaultValue={newContactpersoonTelefoon} label="Telefoon"
                                                           onChange={(e) => setNewContactpersoonTelefoon(e.target.value)}/>
                                                </div>
                                                <div className="mt-2">
                                                    <Input defaultValue={newContactpersoonMobiel} label="Mobiel telnr."
                                                           onChange={(e) => setNewContactpersoonMobiel(e.target.value)}/>
                                                </div>
                                                <div className="mt-2">
                                                    <Input defaultValue={newContactpersoonEmail} label="Email"
                                                           onChange={(e) => setNewContactpersoonEmail(e.target.value)}/>
                                                </div>
                                                <div className="mt-2">
                                                    <Input defaultValue={newContactpersoonOpmerking} label="Opmerking"
                                                           onChange={(e) => setNewContactpersoonOpmerking(e.target.value)}/>
                                                </div>

                                                <div className="mt-4">
                                                    <Button loading={isMailButtonLoading} ripple={false}
                                                            onClick={(e) => {
                                                                const oca = (item["oldContactObject"]["afdeling"] !== undefined) ? item["oldContactObject"]["afdeling"] : ""
                                                                const ocf = (item["oldContactObject"]["functie"] !== undefined) ? item["oldContactObject"]["functie"] : ""
                                                                const oct = (item["oldContactObject"]["telefoon"] !== undefined) ? item["oldContactObject"]["telefoon"] : ""
                                                                const ocm = (item["oldContactObject"]["mobiel_telefoonnummer"] !== undefined) ? item["oldContactObject"]["mobiel_telefoonnummer"] : ""
                                                                const oce = (item["oldContactObject"]["email"] !== undefined) ? item["oldContactObject"]["email"] : ""
                                                                const oco = (item["oldContactObject"]["opmerking"] !== undefined) ? item["oldContactObject"]["opmerking"] : ""

                                                                const nca = (newContactpersoonAfdeling !== undefined) ? newContactpersoonAfdeling : ""
                                                                const ncf = (newContactpersoonFunctie !== undefined) ? newContactpersoonFunctie : ""
                                                                const nct = (newContactpersoonTelefoon !== undefined) ? newContactpersoonTelefoon : ""
                                                                const ncm = (newContactpersoonMobiel !== undefined) ? newContactpersoonMobiel : ""
                                                                const nce = (newContactpersoonEmail !== undefined) ? newContactpersoonEmail : ""
                                                                const nco = (newContactpersoonOpmerking !== undefined) ? newContactpersoonOpmerking : ""

                                                                sendMail("ContactAmendment", {
                                                                    mailData: {
                                                                        senderFirstname: currentUserData["name"]["first"],
                                                                        senderLastname: currentUserData["name"]["last"],
                                                                        contactName: item["contactpersoon"],
                                                                        pageName: item["page"],
                                                                        oldContactObject: {
                                                                            "naam": item["contactpersoon"],
                                                                            "afdeling": oca,
                                                                            "functie": ocf,
                                                                            "telefoon": oct,
                                                                            "mobiel_telefoonnummer": ocm,
                                                                            "email": oce,
                                                                            "opmerking": oco
                                                                        },
                                                                        newContactObject: {
                                                                            "naam": item["contactpersoon"],
                                                                            "afdeling": nca,
                                                                            "functie": ncf,
                                                                            "telefoon": nct,
                                                                            "mobiel": ncm,
                                                                            "email": nce,
                                                                            "opmerking": nco
                                                                        },
                                                                        relatieNummer: item["relatieNummer"],
                                                                        relatieNaam: item["relatieNaam"]
                                                                    }, selectedUserUUID: userSelector
                                                                })
                                                            }} fullWidth>
                                                        Wijziging versturen
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel key="opmerking" value="opmerking" className="p-0">
                                            <div className="mt-6">
                                                <Textarea label="Opmerking" defaultValue={opmerking}
                                                          onChange={(e) => setOpmerking(e.target.value)}/>
                                                <div className="mt-4">
                                                    <Button loading={isMailButtonLoading} ripple={false}
                                                            onClick={(e) => {
                                                                sendMail("FieldComment", {
                                                                    mailData: {
                                                                        senderFirstname: currentUserData["name"]["first"],
                                                                        senderLastname: currentUserData["name"]["last"],
                                                                        fieldName: item["contactpersoon"],
                                                                        pageName: item["page"],
                                                                        opmerking: opmerking,
                                                                        relatieNummer: item["relatieNummer"],
                                                                        relatieNaam: item["relatieNaam"]
                                                                    }, selectedUserUUID: userSelector
                                                                })
                                                            }} fullWidth>
                                                        Opmerking versturen
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabsBody>
                                </Tabs>
                            </CardBody>
                        </Card>
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={showAlert}
                                icon={<AlertIcon/>}
                                action={
                                    <Button
                                        ripple={false}
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setShowAlert(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {alertMessage}
                            </Alert>
                        </div>
                    </Dialog>
                )
            }
        })

        setActiveDialog(newDialog[0])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDialogData, openDialog, newValue, opmerking, isMailButtonLoading, showAlert, userSelector, newContactpersoonAfdeling, newContactpersoonFunctie, newContactpersoonTelefoon, newContactpersoonMobiel, newContactpersoonEmail, newContactpersoonOpmerking])

    useEffect(() => {
        if (relatieNum === undefined || relatieNum === "") {
            setSelected(0)
            setListData(
                <List>
                    <ListItem disabled={true}>
                        Zoek op een klant om data op te halen
                    </ListItem>
                </List>
            )
        } else if (clients.length > 0) {
            if ((clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))).length !== 0) {
                setCompanySearchField(clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam)

                if (selected !== lastselected || lastselected === 0) {
                    if (selected === 1) {
                        navigate(`../${relatieNum}/klantinformatie`)
                    }

                    if (selected === 2) {
                        navigate(`../${relatieNum}/contactpersonen`)
                    }

                    if (selected === 3) {
                        navigate(`../${relatieNum}/statistieken`)
                    }

                    setLastSelected(selected)
                    setListData(
                        <List>
                            <ListItem
                                ripple={false}
                                selected={selected === 1}
                                onClick={() => setSelected(1)}
                            >
                                <ListItemPrefix>
                                    {(() => {
                                        if (selected === 1) {
                                            return <IdentificationIconSolid className="h-5 w-5"/>
                                        } else {
                                            return <IdentificationIconOutline className="h-5 w-5"/>
                                        }
                                    })()}
                                </ListItemPrefix>
                                Klantinformatie
                            </ListItem>
                            <ListItem
                                ripple={false}
                                selected={selected === 2}
                                onClick={() => setSelected(2)}
                            >
                                <ListItemPrefix>
                                    {(() => {
                                        if (selected === 2) {
                                            return <PhoneIconSolid className="h-5 w-5"/>
                                        } else {
                                            return <PhoneIconOutline className="h-5 w-5"/>
                                        }
                                    })()}
                                </ListItemPrefix>
                                Contactpersonen
                            </ListItem>
                            <ListItem
                                ripple={false}
                                selected={selected === 3}
                                onClick={() => setSelected(3)}
                            >
                                <ListItemPrefix>
                                    {(() => {
                                        if (selected === 3) {
                                            return <ChartBarIconSolid className="h-5 w-5"/>
                                        } else {
                                            return <ChartBarIconOutline className="h-5 w-5"/>
                                        }
                                    })()}
                                </ListItemPrefix>
                                Statistieken
                            </ListItem>
                        </List>
                    )
                }
            } else {
                navigate(`/`)
            }
        }
    }, [relatieNum, selected, clients]);

    useEffect(() => {
        if (companySearch !== undefined && companySearch !== "" && companySearch.length > 3) {
            setSelected(0)
            let value = companySearch.trim().toLowerCase()
            let results = []

            if (clients.length > 0) {
                results = clients.filter(function (company) {
                    if (this.count < 6) {
                        let companynametrimmed = company.naam.toString().trim().toLowerCase()
                        let relationtrimmed = company.relatienummer.toString().trim().toLowerCase()
                        let isTrue = false
                        if (companynametrimmed.includes(value) || relationtrimmed.includes(value)) {
                            this.count++
                            isTrue = true
                        }
                        return isTrue
                    }
                }, {count: 0})

                setListData(
                    <List>
                        {results.map((result) => (
                            <ListItem
                                ripple={false}
                                onClick={() => {
                                    navigate(`../${result.relatienummer}/klantinformatie`)
                                    navigate(0)
                                }}
                            >
                                {result.naam}
                                <ListItemSuffix>
                                    <Typography variant="small">
                                        {result.relatienummer}
                                    </Typography>
                                </ListItemSuffix>
                            </ListItem>
                        ))}
                    </List>
                )
            } else {
                setListData(
                    <div className="flex grow justify-center h-[50px] max-h-full">
                        <div className="m-auto">
                            <Spinner/>
                        </div>
                    </div>
                )
            }
        } else if (companySearch !== undefined && companySearch !== "") {
            setSelected(0)
            setListData(
                <div className="flex grow justify-center h-[50px] max-h-full">
                    <div className="m-auto">
                        <Spinner/>
                    </div>
                </div>
            )
        } else if (selected === 0) {
            setListData(
                <List>
                    <ListItem disabled={true}>
                        Zoek op een klant om data op te halen
                    </ListItem>
                </List>
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companySearch, clients, selected]);

    useEffect(() => {
        if (selected === 0) {
            setViewData(welcome)
        }

        if (selected === 1) {
            const handleOpenEditModeDialog = async (field, oldValue) => {
                setActiveDialogData([{
                    type: "EditMode",
                    page: "Klantinformatie",
                    relatieNummer: relatieNum,
                    relatieNaam: clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam,
                    field: field,
                    oldValue: oldValue
                }])

                setNewValue(oldValue)
                setOpmerking('')
                setUserSelector('')
                setOpenDialog(true)
            }

            const handleOpenOpmerkingenEditModeDialog = async (field, oldValue) => {
                setActiveDialogData([{
                    type: "OpmerkingenEditMode",
                    page: "Klantinformatie",
                    relatieNummer: relatieNum,
                    relatieNaam: clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam,
                    field: field,
                    oldValue: oldValue
                }])

                setNewValue(oldValue)
                setOpmerking('')
                setUserSelector('')
                setOpenDialog(true)
            }

            if ((Object.keys(clientinfo).length > 0) && (clients.length > 0)) {
                setViewData(
                    <div>

                        <div className="flex p-6">
                            <div className="grow mr-4">
                                <p className="text-left text-3xl text-black">Klantinformatie {clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam}</p>
                            </div>
                            <div className="grid justify-items-end">
                                <div className="flex items-center gap-4">
                                    {(() => {
                                        if (clientinfo["website"] !== undefined) {
                                            return (
                                                <div className="flex items-center">
                                                    <a href={clientinfo["website"]} target="_blank" rel="noreferrer">
                                                        <Button
                                                            variant="outlined"
                                                            size="sm"
                                                            ripple={false}
                                                            className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                                        >
                                                            <FontAwesomeIcon icon={faGlobe}/><span
                                                            className="ml-3">Website</span>
                                                        </Button>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (clientinfo["vrije_velden"]["kledingkaart"] !== undefined) {
                                            return (
                                                <div className="flex items-center">
                                                    <a href={clientinfo["vrije_velden"]["kledingkaart"]} target="_blank"
                                                       rel="noreferrer">
                                                        <Button
                                                            variant="outlined"
                                                            size="sm"
                                                            ripple={false}
                                                            className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/><span
                                                            className="ml-3">Kledingkaart</span>
                                                        </Button>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (clientinfo["vrije_velden"]["klantenkaart"] !== undefined) {
                                            return (
                                                <div className="flex items-center">
                                                    <a href={clientinfo["vrije_velden"]["klantenkaart"]} target="_blank"
                                                       rel="noreferrer">
                                                        <Button
                                                            variant="outlined"
                                                            size="sm"
                                                            ripple={false}
                                                            className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/><span
                                                            className="ml-3">Klantenkaart</span>
                                                        </Button>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className="px-6 grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-left text-1xl text-black"><strong>Adres</strong></p>
                                <span className="block border-t border-blue-gray-50 mt-4 mb-4"/>
                                <table className="w-full table-auto text-left">
                                    <tbody>
                                    <tr key="Straat en huisnummer" className="even:bg-blue-gray-50/50">
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Straat en huisnummer
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["adres"]["straatnaam"] + " " + clientinfo["adres"]["huisnummer"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return clientinfo["adres"]["straatnaam"] + " " + clientinfo["adres"]["huisnummer"]
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end m-auto">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Straat en huisnummer", (clientinfo["adres"]["straatnaam"] + " " + clientinfo["adres"]["huisnummer"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key="Postcode" className="even:bg-blue-gray-50/50">
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Postcode
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["adres"]["postcode"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return clientinfo["adres"]["postcode"]
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Postcode", (clientinfo["adres"]["postcode"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key="Plaats" className="even:bg-blue-gray-50/50">
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Plaats
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["adres"]["plaats"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return clientinfo["adres"]["plaats"]
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Plaats", (clientinfo["adres"]["plaats"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key="Land" className="even:bg-blue-gray-50/50">
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Land
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["adres"]["land"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return clientinfo["adres"]["land"]
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Land", (clientinfo["adres"]["land"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key="Telefoon" className="even:bg-blue-gray-50/50">
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Telefoon
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["telefoon"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return <a className="text-blue-700"
                                                                      href={"tel:" + clientinfo["telefoon"]}>{clientinfo["telefoon"]}</a>
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Telefoon", (clientinfo["telefoon"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key="Email" className="even:bg-blue-gray-50/50">
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Email
                                            </Typography>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["email"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return <a className="text-blue-700"
                                                                      href={"mailto:" + clientinfo["email"]}>{clientinfo["email"]}</a>
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Email", (clientinfo["email"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key="Website" className="even:bg-blue-gray-50/50">
                                        <td className="p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                Website
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <div className="flex gap-2 h-[25px]">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal m-auto"
                                                >
                                                    {(() => {
                                                        if (clientinfo["website"] === undefined) {
                                                            return <QuestionMarkCicleIconOutline
                                                                className="h-5 w-5"/>
                                                        } else {
                                                            return <a className="text-blue-700"
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                      href={clientinfo["website"]}>{clientinfo["website"]}</a>
                                                        }
                                                    })()}
                                                </Typography>
                                                <div className="grow">
                                                    {(() => {
                                                        if (editMode) {
                                                            return (
                                                                <div className="flex justify-end">
                                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                                variant="text"
                                                                                ripple={false}
                                                                                onClick={() => handleOpenEditModeDialog("Website", (clientinfo["website"]))}>
                                                                        <FontAwesomeIcon icon={faPencil}/>
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <p className="text-left text-1xl text-black"><strong>Opmerkingen</strong></p>
                                <Typography variant="small">Deze tekst wordt gebruikt door onze verkopers om op de
                                    juiste
                                    manier de afspraken na te komen</Typography>
                                <span className="block border-t border-blue-gray-50 mt-4 mb-4"/>
                                <div className="rounded-lg bg-blue-gray-50/50 text-blue-gray-900 p-4 relative">
                                    {(() => {
                                        let textWithBreaks = ""
                                        clientinfo["opmerkingen"].split('\\n').map((text, index) => {
                                            if (index + 1 === clientinfo["opmerkingen"].split('\\n').length) {
                                                textWithBreaks += `${text}`
                                            } else {
                                                textWithBreaks += `${text}\n`
                                            }
                                        })

                                        console.log(textWithBreaks)

                                        if (editMode) {
                                            return (
                                                <div
                                                    className="flex justify-end m-auto absolute top-[10px] right-[10px]">
                                                    <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                variant="text"
                                                                ripple={false}
                                                                onClick={() => handleOpenOpmerkingenEditModeDialog("Opmerkingen", textWithBreaks)}>
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </IconButton>
                                                </div>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if (clientinfo["opmerkingen"] === undefined) {
                                            return <MinusIconSolid
                                                className="h-5 w-5"/>
                                        } else {
                                            const textWithBreaks = clientinfo["opmerkingen"].split('\\n').map((text, index) => {
                                                if (index + 1 === clientinfo["opmerkingen"].split('\\n').length) {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {text}
                                                        </React.Fragment>
                                                    )
                                                } else {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {text}
                                                            <br/>
                                                        </React.Fragment>
                                                    )
                                                }
                                            })

                                            return <p>{textWithBreaks}</p>
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                        <div className="p-6">
                            <p className="text-left text-1xl text-black"><strong>Vrije informatievelden</strong></p>
                            <span className="block border-t border-blue-gray-50 mt-4 mb-4"/>
                            <table className="w-full table-auto text-left">
                                <tbody>
                                <tr key="Kledingkaart" className="even:bg-blue-gray-50/50">
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            Kledingkaart
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {(() => {
                                                if (clientinfo["vrije_velden"]["kledingkaart"] === undefined) {
                                                    return <MinusIconSolid
                                                        className="h-5 w-5"/>
                                                } else {
                                                    return <a className="text-blue-700"
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              href={clientinfo["vrije_velden"]["kledingkaart"]}>{clientinfo["vrije_velden"]["kledingkaart"]}</a>
                                                }
                                            })()}
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <div className="flex justify-end gap-2 h-[25px]">
                                            {(() => {
                                                if (editMode) {
                                                    return (
                                                        <div className="flex justify-end">
                                                            <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                        variant="text"
                                                                        ripple={false}
                                                                        onClick={() => handleOpenEditModeDialog("Kledingkaart", (clientinfo["vrije_velden"]["kledingkaart"]))}>
                                                                <FontAwesomeIcon icon={faPencil}/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                                <tr key="Klantenkaart" className="even:bg-blue-gray-50/50">
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            Klantenkaart
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {(() => {
                                                if (clientinfo["vrije_velden"]["klantenkaart"] === undefined) {
                                                    return <MinusIconSolid
                                                        className="h-5 w-5"/>
                                                } else {
                                                    return <a className="text-blue-700"
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              href={clientinfo["vrije_velden"]["klantenkaart"]}>{clientinfo["vrije_velden"]["klantenkaart"]}</a>
                                                }
                                            })()}
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <div className="flex justify-end gap-2 h-[25px]">
                                            {(() => {
                                                if (editMode) {
                                                    return (
                                                        <div className="flex justify-end">
                                                            <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                        variant="text"
                                                                        ripple={false}
                                                                        onClick={() => handleOpenEditModeDialog("Klantenkaart", (clientinfo["vrije_velden"]["klantenkaart"]))}>
                                                                <FontAwesomeIcon icon={faPencil}/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                                <tr key="Gereedschapbeheer" className="even:bg-blue-gray-50/50">
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            Gereedschapbeheer
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {(() => {
                                                if (clientinfo["vrije_velden"]["gereedschapbeheer"] === undefined) {
                                                    return <MinusIconSolid
                                                        className="h-5 w-5"/>
                                                } else {
                                                    return clientinfo["vrije_velden"]["gereedschapbeheer"]
                                                }
                                            })()}
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <div className="flex justify-end gap-2">
                                            <a href="https://docs.google.com/document/d/1fGm_xjfiIogtrh0fyNOamUF1ntF1thimKs5OSglSyHs/edit?usp=sharing"
                                               target="_blank" rel="noreferrer">
                                                <Button
                                                    variant="text"
                                                    size="sm"
                                                    ripple={false}
                                                    className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                                >
                                                    <FontAwesomeIcon icon={faEuroSign}/><span
                                                    className="ml-3">Prijslijst</span>
                                                </Button>
                                            </a>
                                            {(() => {
                                                if (editMode) {
                                                    return (
                                                        <div className="flex justify-end">
                                                            <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                        variant="text"
                                                                        ripple={false}
                                                                        onClick={() => handleOpenEditModeDialog("Gereedschapbeheer", (clientinfo["vrije_velden"]["gereedschapbeheer"]))}>
                                                                <FontAwesomeIcon icon={faPencil}/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                                <tr key="Email nieuwsbrief" className="even:bg-blue-gray-50/50">
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            Email nieuwsbrief
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {(() => {
                                                if (clientinfo["vrije_velden"]["email_nieuwsbrief"] === undefined) {
                                                    return <MinusIconSolid
                                                        className="h-5 w-5"/>
                                                } else {
                                                    return <a className="text-blue-700"
                                                              href={"mailto:" + clientinfo["vrije_velden"]["email_nieuwsbrief"]}>{clientinfo["vrije_velden"]["email_nieuwsbrief"]}</a>
                                                }
                                            })()}
                                        </Typography>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <div className="flex justify-end gap-2 h-[25px]">
                                            {(() => {
                                                if (editMode) {
                                                    return (
                                                        <div className="flex justify-end">
                                                            <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                        variant="text"
                                                                        ripple={false}
                                                                        onClick={() => handleOpenEditModeDialog("Email nieuwsbrief", (clientinfo["vrije_velden"]["email_nieuwsbrief"]))}>
                                                                <FontAwesomeIcon icon={faPencil}/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                                <tr key="Segmentatie" className="even:bg-blue-gray-50/50">
                                    <td className="p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            Segmentatie
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {(() => {
                                                if (clientinfo["vrije_velden"]["segmentatie"] === undefined) {
                                                    return <MinusIconSolid
                                                        className="h-5 w-5"/>
                                                } else {
                                                    return clientinfo["vrije_velden"]["segmentatie"]
                                                }
                                            })()}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <div className="flex justify-end gap-2 h-[25px]">
                                            {(() => {
                                                if (editMode) {
                                                    return (
                                                        <div className="flex justify-end">
                                                            <IconButton className="h-[25px] w-[25px]" size="sm"
                                                                        variant="text"
                                                                        ripple={false}
                                                                        onClick={() => handleOpenEditModeDialog("Segmentatie", (clientinfo["vrije_velden"]["segmentatie"]))}>
                                                                <FontAwesomeIcon icon={faPencil}/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            } else {
                setViewData(
                    <div className="flex grow justify-center h-full">
                        <div className="m-auto">
                            <Spinner/>
                        </div>
                    </div>
                )
            }
        }

        if (selected === 2) {
            const handleOpenEditModeDialog = async (contactpersoon, oldContactObject) => {
                setActiveDialogData([{
                    type: "ContactpersonenEditMode",
                    page: "Contactpersonen",
                    relatieNummer: relatieNum,
                    relatieNaam: clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam,
                    contactpersoon: contactpersoon,
                    oldContactObject: oldContactObject
                }])

                setNewContactpersoonAfdeling(oldContactObject["afdeling"])
                setNewContactpersoonFunctie(oldContactObject["functie"])
                setNewContactpersoonTelefoon(oldContactObject["telefoon"])
                setNewContactpersoonMobiel(oldContactObject["mobiel_telefoonnummer"])
                setNewContactpersoonEmail(oldContactObject["email"])
                setNewContactpersoonOpmerking(oldContactObject["opmerking"])
                setOpmerking('')
                setUserSelector('')
                setOpenDialog(true)
            }

            if ((Object.keys(clientcontacts).length > 0) && (clients.length > 0)) {
                const CONTACT_TABLE_HEAD = ["Naam", "Afdeling", "Functie", "Telefoon", "Mobiel telnr", "Email", "Opmerking"];

                /*const getItemProps = (index) =>
                    ({
                        variant: contactTablePage === index ? "filled" : "text",
                        color: "gray",
                        onClick: () => setContactTablePage(index),
                    })

                const next = () => {
                    if (contactTablePage === contactTablePages) return;

                    setContactTablePage(contactTablePage + 1);
                };

                const prev = () => {
                    if (contactTablePage === 0) return;

                    setContactTablePage(contactTablePage - 1);
                };*/

                setViewData(
                    <div>
                        <div className="flex mr-6">
                            <div className="grow mr-4">
                                <p className="text-3xl text-black p-6">Contactpersonen {clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam}</p>
                            </div>
                            <div className="grid justify-items-end">
                                <div className="flex items-center gap-4">
                                    <Button
                                        variant="gradient"
                                        size="sm"
                                        ripple={false}
                                        className="from-excelgreen-100 to-excelgreen-200 block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                        onClick={() => {
                                            const link = document.createElement("a");

                                            var instance = new TableExport(document.getElementById("contactpersonentable"), {
                                                formats: ['xlsx'],
                                                exportButtons: false,
                                            })

                                            var XLSX = instance.CONSTANTS.FORMAT.XLSX;
                                            var exportDataXLS = instance.getExportData()["contactpersonentable"][XLSX];

                                            instance.export2file(exportDataXLS.data, exportDataXLS.mimeType, exportDataXLS.filename, exportDataXLS.fileExtension)
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExport}/><span
                                        className="ml-3">exporteer als xlsx</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="mx-6 overflow-x-scroll">
                            <table id="contactpersonentable" className="w-full min-w-max table-auto text-left">
                                <thead>
                                <tr>
                                    {CONTACT_TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {(() => {
                                    let tabledata = []
                                    clientcontacts["contacts"].forEach((contactitem) => {
                                        let naam
                                        let afdeling
                                        let functie
                                        let telefoon
                                        let mobiel_telefoonnummer
                                        let email
                                        let opmerking

                                        if (contactitem["naam"] === undefined) {
                                            naam = <QuestionMarkCicleIconOutline
                                                className="h-5 w-5 m-auto"/>
                                        } else {
                                            naam = contactitem["naam"]
                                        }
                                        if (contactitem["afdeling"] === undefined) {
                                            afdeling = <QuestionMarkCicleIconOutline
                                                className="h-5 w-5 m-auto"/>
                                        } else {
                                            afdeling = contactitem["afdeling"]
                                        }
                                        if (contactitem["afdeling"] === undefined) {
                                            functie = <QuestionMarkCicleIconOutline
                                                className="h-5 w-5 m-auto"/>
                                        } else {
                                            functie = contactitem["afdeling"]
                                        }
                                        if (contactitem["telefoon"] === undefined) {
                                            telefoon = <QuestionMarkCicleIconOutline
                                                className="h-5 w-5 m-auto"/>
                                        } else {
                                            telefoon = contactitem["telefoon"]
                                        }
                                        if (contactitem["mobiel_telefoonnummer"] === undefined) {
                                            mobiel_telefoonnummer = <QuestionMarkCicleIconOutline
                                                className="h-5 w-5 m-auto"/>
                                        } else {
                                            mobiel_telefoonnummer = contactitem["mobiel_telefoonnummer"]
                                        }
                                        if (contactitem["email"] === undefined) {
                                            email = <QuestionMarkCicleIconOutline
                                                className="h-5 w-5 m-auto"/>
                                        } else {
                                            email = contactitem["email"]
                                        }
                                        if (contactitem["opmerking"] === undefined) {
                                            opmerking = <MinusIconSolid className="h-5 w-5 m-auto"/>
                                        } else {
                                            opmerking = contactitem["opmerking"]
                                        }

                                        if (contactitem["verborgen"] !== true) {
                                            tabledata.push(
                                                <tr key={naam} className="p4 even:bg-blue-gray-50/50">
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray"
                                                                    className="font-normal">
                                                            {naam}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray"
                                                                    className="font-normal">
                                                            {afdeling}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray"
                                                                    className="font-normal">
                                                            {functie}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray"
                                                                    className="font-normal">
                                                            {telefoon}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray"
                                                                    className="font-normal">
                                                            {mobiel_telefoonnummer}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray"
                                                                    className="font-normal">
                                                            {email}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <div className="flex">
                                                            <div className="grow">
                                                                <Typography variant="small" color="blue-gray"
                                                                            className="font-normal">
                                                                    {opmerking}
                                                                </Typography>
                                                            </div>
                                                            <div className="justify-end gap-2 h-[25px]">
                                                                {(() => {
                                                                    if (editMode) {
                                                                        return (
                                                                            <div className="flex justify-end">
                                                                                <IconButton
                                                                                    className="h-[25px] w-[25px]"
                                                                                    size="sm"
                                                                                    variant="text"
                                                                                    ripple={false}
                                                                                    onClick={() => handleOpenEditModeDialog(naam, contactitem)}>
                                                                                    <FontAwesomeIcon icon={faPencil}/>
                                                                                </IconButton>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })

                                    /*  PAGINATION
                                   setContactTablePages(Math.floor((tabledata.length + 1) / 10))

                                    let range

                                    if (contactTablePage === 0) {
                                        range = [0, 9]
                                    } else {
                                        range = [10 * contactTablePage, 9 + (10 * contactTablePage)]
                                    }

                                    let count = 0

                                    return (
                                        tabledata.map(item => {
                                            if ((count >= range[0]) && (count <= range[1])) {
                                                count++
                                                return item
                                            }
                                            count++
                                        })
                                    )
                                     */

                                    return (
                                        tabledata.map(item => {
                                            return item
                                        })
                                    )
                                })()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )

                /*  PAGINATION BUTTONS
                        <div className="flex grow justify-center p-4">
                            <div className="flex items-center gap-4">
                                <Button
                                    ripple={false}
                                    variant="text"
                                    size="sm"
                                    className="flex items-center gap-2"
                                    onClick={prev}
                                    disabled={contactTablePage === 0}
                                >
                                    <ArrowLeftIcon strokeWidth={2} className="h-4 w-4"/> Vorige
                                </Button>
                                <div className="flex items-center gap-2">
                                    {Array.from(Array(contactTablePages + 1), (e, i) => {
                                        console.log(contactTablePages)
                                        return <IconButton size="sm" ripple={false} {...getItemProps(i)}>{i + 1}</IconButton>
                                    })}
                                </div>
                                <Button
                                    ripple={false}
                                    variant="text"
                                    size="sm"
                                    className="flex items-center gap-2"
                                    onClick={next}
                                    disabled={contactTablePage === contactTablePages}
                                >
                                    Volgende
                                    <ArrowRightIcon strokeWidth={2} className="h-4 w-4"/>
                                </Button>
                            </div>
                        </div>
                 */
            } else {
                setViewData(
                    <div className="flex grow justify-center h-full">
                        <div className="m-auto">
                            <Spinner/>
                        </div>
                    </div>
                )
            }
        }

        if (selected === 3) {
            let chart1Config = {}
            let chart2Config = {}
            let config1Ready = true
            let config2Ready = true
            let dateString = ""

            let years = []

            if (Object.keys(chart1Options).length !== 0) {
                for (const [key, value] of Object.entries(chart1Options)) {
                    years.push(key)
                }

                years.sort(function (a, b) {
                    return b - a
                })

                years.splice(years.length - 1, 1)
            }

            if (chart1Quarter === undefined || chart1Year === undefined) {
                dateString = "nog geen jaar of kwartaal geselecteerd"
            } else {
                dateString = `Q${chart1Quarter} ${chart1Year - 1} / Q${chart1Quarter} ${chart1Year}`
            }


            if (chart1Loading === false && Object.keys(chart1Data).length !== 0) {
                let data = {}
                data[chart1Year - 1] = {}
                data[chart1Year - 1][chart1Quarter] = []
                data[chart1Year] = {}
                data[chart1Year][chart1Quarter] = []

                try {
                    let allCategories = []

                    for (const [key] of Object.entries(chart1Data[chart1Year][chart1Quarter]["data"])) {
                        if (!allCategories.includes(key)) {
                            allCategories.push(key)
                        }
                    }

                    for (const [key] of Object.entries(chart1Data[chart1Year - 1][chart1Quarter]["data"])) {
                        if (!allCategories.includes(key)) {
                            allCategories.push(key)
                        }
                    }

                    allCategories.sort()

                    allCategories.forEach((category) => {
                        let val1 = null
                        let val2 = null

                        for (const [key, value] of Object.entries(chart1Data[chart1Year - 1][chart1Quarter]["data"])) {
                            if (key === category) {
                                val1 = value
                            }
                        }

                        for (const [key, value] of Object.entries(chart1Data[chart1Year][chart1Quarter]["data"])) {
                            if (key === category) {
                                val2 = value
                            }
                        }

                        data[chart1Year - 1][chart1Quarter].push(
                            {
                                x: category,
                                y: val1
                            }
                        )

                        data[chart1Year][chart1Quarter].push(
                            {
                                x: category,
                                y: val2
                            }
                        )
                    })

                    chart1Config = {
                        type: "bar",
                        height: 750,
                        series: [
                            {
                                name: chart1Data[chart1Year - 1][chart1Quarter]["name"],
                                data: data[chart1Year - 1][chart1Quarter],
                            },
                            {
                                name: chart1Data[chart1Year][chart1Quarter]["name"],
                                data: data[chart1Year][chart1Quarter],
                            }
                        ],
                        options: {
                            chart: {
                                toolbar: {
                                    show: false,
                                },
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            colors: ["#002856", "#cf152d"],
                            plotOptions: {
                                bar: {
                                    columnWidth: "80%",
                                    borderRadius: 3
                                },
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                lineCap: "butt",
                                curve: "straight",
                                colors: ['transparent']
                            },
                            xaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                },
                                labels: {
                                    maxHeight: undefined,
                                    rotate: -55,
                                    style: {
                                        colors: "#616161",
                                        fontSize: "12px",
                                        fontFamily: "inherit",
                                        fontWeight: 400,
                                    },
                                },
                            },
                            yaxis: {
                                labels: {
                                    style: {
                                        colors: "#616161",
                                        fontSize: "12px",
                                        fontFamily: "inherit",
                                        fontWeight: 400,
                                    },
                                    formatter: function (value) {
                                        if (value !== null) {
                                            return parseFloat(value).toLocaleString("nl-NL", {
                                                style: "currency",
                                                currency: "EUR"
                                            })
                                        }
                                    }
                                },
                            },
                            grid: {
                                show: true,
                                borderColor: "#dddddd",
                                strokeDashArray: 5,
                                xaxis: {
                                    lines: {
                                        show: true,
                                    },
                                },
                                padding: {
                                    top: 5,
                                    right: 20,
                                },
                            },
                            fill: {
                                opacity: 1,
                            },
                            tooltip: {
                                theme: "light",
                                shared: true,
                                intersect: false
                            },
                        },
                    }

                    config1Ready = true
                } catch {
                    config1Ready = false
                }


            }

            if (chart2Loading === false && Object.keys(chart2Data).length !== 0) {
                let data = []

                try {
                    for (const [key, value] of Object.entries(chart2Data)) {
                        data.push({
                            x: key,
                            y: value["total"]
                        })
                    }

                    chart2Config = {
                        type: "bar",
                        height: 450,
                        series: [
                            {
                                name: "Omzet",
                                data: data,
                            }
                        ],
                        options: {
                            chart: {
                                toolbar: {
                                    show: false,
                                },
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            colors: ["#002856"],
                            plotOptions: {
                                bar: {
                                    columnWidth: "60%",
                                    borderRadius: 3
                                },
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                lineCap: "butt",
                                curve: "straight",
                                colors: ['transparent']
                            },
                            xaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                },
                                labels: {
                                    maxHeight: undefined,
                                    rotate: -55,
                                    style: {
                                        colors: "#616161",
                                        fontSize: "12px",
                                        fontFamily: "inherit",
                                        fontWeight: 400,
                                    },
                                },
                            },
                            yaxis: {
                                labels: {
                                    style: {
                                        colors: "#616161",
                                        fontSize: "12px",
                                        fontFamily: "inherit",
                                        fontWeight: 400,
                                    },
                                    formatter: function (value) {
                                        if (value !== null) {
                                            return parseFloat(value).toLocaleString("nl-NL", {
                                                style: "currency",
                                                currency: "EUR"
                                            })
                                        }
                                    }
                                },
                            },
                            grid: {
                                show: true,
                                borderColor: "#dddddd",
                                strokeDashArray: 5,
                                xaxis: {
                                    lines: {
                                        show: true,
                                    },
                                },
                                padding: {
                                    top: 5,
                                    right: 20,
                                },
                            },
                            fill: {
                                opacity: 1,
                            },
                            tooltip: {
                                theme: "light",
                                shared: true,
                                intersect: false
                            },
                        },
                    }

                    config2Ready = true
                } catch {
                    config2Ready = false
                }


            }

            setViewData(
                <div>
                    <p className="text-3xl text-black p-6">Statistieken {clients.filter((company) => parseInt(company.relatienummer) === parseInt(relatieNum))[0].naam}</p>
                    <div className="p-6">
                        <div className="flex">
                            <div className="grow mr-4">
                                <p className="text-left text-1xl text-black"><strong>Omzet per productcategorie
                                    ({dateString})</strong></p>
                                <span className="block border-t border-blue-gray-50 mt-4 mb-4"/>
                            </div>
                            <div className="grid justify-items-end mr-4">
                                <div className="w-[200px]">
                                    {(() => {
                                        if (chart1YearSelectLoading === true) {
                                            return (
                                                <Select label="Jaar" disabled={true}>
                                                    <Option value="-">-</Option>
                                                </Select>
                                            )
                                        } else {
                                            return (
                                                <Select label="Jaar" onChange={(e) => {
                                                    setPreviousChart1Year(chart1Year)
                                                    setChart1Year(e)
                                                }}>
                                                    {
                                                        years.map((year) =>
                                                            <Option value={year}>{year}</Option>
                                                        )
                                                    }
                                                </Select>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="grid justify-items-end">
                                <div className="w-[200px]">
                                    {(() => {
                                        if (chart1QuarterSelectLoading === false && quarters !== undefined) {
                                            return (
                                                <Select label="Kwartaal"
                                                        onChange={(e) => setChart1Quarter(e)}>
                                                    {quarters.map((quarter) =>
                                                        <Option value={quarter}>Q{quarter}</Option>
                                                    )}
                                                </Select>
                                            )
                                        } else {
                                            return (
                                                <Select label="Kwartaal" disabled={true}>
                                                    <Option value="-">-</Option>
                                                </Select>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                        {
                            (() => {
                                if (chart1Loading === true && config1Ready === true) {
                                    return (
                                        <div className="flex grow justify-center h-[50px] max-h-full">
                                            <div className="m-auto">
                                                <Spinner/>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (<Chart {...chart1Config} />)
                                }
                            })()
                        }
                    </div>
                    <div className="p-6">
                        <div className="flex">
                            <div className="grow mr-4">
                                <p className="text-left text-1xl text-black"><strong>Omzetgegevens</strong></p>
                                <span className="block border-t border-blue-gray-50 mt-4 mb-4"/>
                            </div>
                        </div>
                        {
                            (() => {
                                if (chart2Loading === true && config2Ready === true) {
                                    return (
                                        <div className="flex grow justify-center h-[50px] max-h-full">
                                            <div className="m-auto">
                                                <Spinner/>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (<Chart {...chart2Config} />)
                                }
                            })()
                        }
                    </div>
                </div>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, clientinfo, clientcontacts, clients, contactTablePages,
        contactTablePage, chart1Loading, chart1Data, chart1Options, chart1Quarter, chart1Year,
        chart1YearSelectLoading, chart1QuarterSelectLoading, quarters, editMode])

    return (
        <div className="h-full relative">
            <div
                className="2xl:hidden m-[20px] duration-100 shadow-none z-50 fixed bottom-[50%] left-[-30px]">
                <div className=""></div>
                <Button variant="outlined"
                        ripple={false}
                        className="duration-100 hover:shadow-2xl w-[10px] hover:w-[50px]"
                        size="sm"
                        onClick={openDrawer}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </Button>
            </div>

            <Drawer open={openSideDrawer} onClose={closeDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between">
                    <div></div>
                    <IconButton ripple={false} variant="text" color="blue-gray"
                                onClick={closeDrawer}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>
                <div className="p-2">
                    <Input
                        defaultValue={companySearchField}
                        onChange={(e) => setCompanySearch(e.target.value)}
                        icon={<MagnifyingGlassIcon className="h-5 w-5"/>}
                        label="Zoek op klant of relatienummer"/>
                </div>
                <div className="max-h-[80%] overflow-y-auto">
                    {listData}
                </div>
            </Drawer>

            <div>
                {activeDialog}
            </div>

            <div className="hidden 2xl:block h-full overflow-y-hidden">
                <div className="flex w-full h-full">

                    <div className="flex-none">
                        <div className="m-[20px] w-[300px] max-h-[7%]">
                            <Card
                                className="w-[300px] p-4 shadow-lg max-h-[70%] fixed top-[90px]">
                                <div className="p-2">
                                    <Input
                                        defaultValue={companySearchField}
                                        onChange={(e) => setCompanySearch(e.target.value)}
                                        icon={<MagnifyingGlassIcon className="h-5 w-5"/>}
                                        label="Zoek op klant of relatienummer"/>
                                </div>
                                <div className="overflow-y-auto">
                                    {listData}
                                </div>
                            </Card>
                        </div>
                    </div>

                    <div className="grow overflow-x-hidden">
                        <div className="p-[20px] h-full">
                            <div className="h-full">
                                <Card className="h-full p-4 shadow-lg">
                                    {viewData}
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="2xl:hidden h-full p-[20px]">
                <div className="h-full">
                    <Card className="h-full w-full p-4 shadow-lg">
                        {viewData}
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
