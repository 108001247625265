import '../output.css';
import React, {useEffect, useState} from "react";
import {auth} from "../firebase";
import {signInWithEmailAndPassword, browserLocalPersistence, browserSessionPersistence} from "firebase/auth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Alert, Button, Navbar} from "@material-tailwind/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome} from '@fortawesome/free-solid-svg-icons'
import {Token} from "../apiHandler";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
        >
            <path
                fillRule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function LogIn() {
    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                await Token.create()
                navigate("/dashboard");
            }
        })

        document.title = "GBI Dashboard | Inloggen"
    })

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notice, setNotice] = useState("");
    const [open, setOpen] = useState(false);
    const [persistentSession, setPersistentSession] = useState(false);
    const [searchParams] = useSearchParams();
    const redirectTo = searchParams.get("redirectTo")

    const loginWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        try {
            if (persistentSession === true) {
                await auth.setPersistence(browserLocalPersistence)
                    .then(async () => {
                        await signInWithEmailAndPassword(auth, email, password);
                        navigate(redirectTo == null ? "/dashboard" : redirectTo);
                    })
            } else {
                await auth.setPersistence(browserSessionPersistence)
                    .then(async () => {
                        await signInWithEmailAndPassword(auth, email, password);
                        navigate(redirectTo == null ? "/dashboard" : redirectTo);
                    })
            }
        } catch {
            setNotice("Email of wachtwoord is ongeldig");
            setOpen(true)
        }
    }

    return (
        <div>
            <div className="flex flex-col h-screen">
                <header>
                    <Navbar
                        className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
                        <div className="flex items-center justify-between text-blue-gray-900">
                            <img
                                alt="Logo"
                                className="h-9"
                                src="/gbidashboardlogo.png"
                            />
                            <div className="flex items-center gap-4">
                                <div className="flex items-center gap-x-1">
                                    <a href="/">
                                        <Button
                                            ripple={false}
                                            variant="gradient"
                                            size="sm"
                                            className="block w-full select-none rounded-lg bg-gradient-to-tr from-gbiblue-100 to-gbiblue-200 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gbiblue-100/10 transition-all hover:shadow-lg hover:shadow-gbiblue-100/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                        >
                                            <FontAwesomeIcon icon={faHome}/><span className="ml-3">Home</span>
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Navbar>
                    <div className="w-full fixed">
                        <div className="m-4">
                            <Alert
                                variant="gradient"
                                color="amber"
                                open={open}
                                icon={<Icon/>}
                                action={
                                    <Button
                                        variant="text"
                                        color="black"
                                        size="sm"
                                        className="!absolute top-3 right-3"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </Button>
                                }
                            >
                                {notice}
                            </Alert>
                        </div>
                    </div>
                </header>
                <main className="flex-1 h-full">
                    <div className="flex justify-center h-full">
                        <div className="m-auto">
                            <div
                                className="relative flex flex-col text-gray-700 bg-white w-96 rounded-xl bg-clip-border">
                                <form method="post">
                                    <div className="flex flex-col gap-4 p-6">
                                        <div className="relative h-11 w-full min-w-[200px]">
                                            <input
                                                id="user-email"
                                                name="email"
                                                type="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 border-t-transparent text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gbiblue-100 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                                placeholder=" "/>
                                            <label
                                                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gbiblue-100 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gbiblue-100 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gbiblue-100 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email
                                            </label>
                                        </div>
                                        <div className="relative h-11 w-full min-w-[200px]">
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 border-t-transparent text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gbiblue-100 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                                placeholder=" "/>
                                            <label
                                                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gbiblue-100 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gbiblue-100 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gbiblue-100 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Wachtwoord
                                            </label>
                                        </div>
                                        <div className="-ml-2.5">
                                            <div className="inline-flex items-center">
                                                <label htmlFor="checkbox"
                                                       className="relative flex items-center p-3 rounded-full cursor-pointer">
                                                    <input type="checkbox"
                                                           onChange={(e) => setPersistentSession(e.target.checked)}
                                                           className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gbiblue-100 checked:bg-gbiblue-100 checked:before:bg-gbiblue-100 hover:before:opacity-10"
                                                           id="checkbox"/>
                                                    <span
                                                        className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5"
                                                 viewBox="0 0 20 20" fill="currentColor" stroke="currentColor"
                                                 stroke-width="1">
                                                <path fill-rule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clip-rule="evenodd"></path>
                                            </svg>
                                        </span>
                                                </label>
                                                <label
                                                    className="mt-px font-light text-blue-gray-500 cursor-pointer select-none"
                                                    htmlFor="checkbox">
                                                    Ingelogd blijven
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-6 pt-0">
                                        <button
                                            onClick={(e) => loginWithUsernameAndPassword(e)}
                                            className="block w-full select-none rounded-lg bg-gradient-to-tr from-gbiblue-100 to-gbiblue-200 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gbiblue-100/10 transition-all hover:shadow-lg hover:shadow-gbiblue-100/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                            type="submit">
                                            Inloggen
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
                <footer>
                    <span className="block border-t border-blue-gray-50"/>
                    <p className="block font-sans text-base antialiased font-normal leading-relaxed text-center text-blue-gray-900 mb-4 mt-4">
                        © 2024 GBI Dashboard
                    </p>
                </footer>
            </div>
        </div>
    );
}

export default LogIn;
