import {Outlet, useLocation} from "react-router-dom"
import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom";
import {auth, db} from "../firebase";
import {signOut} from "firebase/auth";
import {
    Navbar,
    Button
} from "@material-tailwind/react";
import {collection, query, where, getDocs} from "firebase/firestore";
import {faGauge} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AdminLayoutLoggedin = () => {
    const navigate = useNavigate();
    const ranonauthchange = useRef(false)
    const prevLocation = useLocation()

    useEffect(() => {
        if (ranonauthchange.current === false) {
            ranonauthchange.current = true
            auth.onAuthStateChanged(async (user) => {
                if (user) {
                    let userdata
                    let gbidata

                    const userq = query(collection(db, "users"), where("uuid", "==", user.uid));
                    const userquerySnapshot = await getDocs(userq);
                    userquerySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        userdata = doc.data()
                    });

                    const gbiq = query(collection(db, "gbibedrijven"), where("gbiid", "==", userdata["gbiid"]));
                    const gbiquerySnapshot = await getDocs(gbiq);
                    gbiquerySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        gbidata = doc.data()
                    });

                    if ((userdata["role"] === "admin") || (userdata["role"] === "companyadmin")) {
                        setPage(
                            <div className="flex flex-col h-screen">
                                <Navbar
                                    className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
                                    <div className="flex items-center justify-between text-blue-gray-900">
                                        <img
                                            alt="Logo"
                                            className="h-9"
                                            src="/gbidashboardlogo.png"
                                        />
                                        <div className="flex items-center gap-4">
                                            <div className="flex items-center">
                                                {(() => {
                                                    if ((userdata["role"] === "admin") || (userdata["role"] === "companyadmin")) {
                                                        return (
                                                            <a href="/dashboard">
                                                                <Button
                                                                    variant="text"
                                                                    size="sm"
                                                                    ripple={false}
                                                                    className="block w-full select-none text-center align-middle font-sans text-xs font-bold uppercase"
                                                                >
                                                                    <FontAwesomeIcon icon={faGauge}/><span
                                                                    className="ml-3">Dashboard</span>
                                                                </Button>
                                                            </a>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                            <div className="flex items-center">
                                                <Button
                                                    onClick={(e) => logoutUser(e)}
                                                    variant="gradient"
                                                    size="sm"
                                                    ripple={false}
                                                    className="block w-full select-none rounded-lg bg-gradient-to-tr from-gbiblue-100 to-gbiblue-200 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gbiblue-100/10 transition-all hover:shadow-lg hover:shadow-gbiblue-100/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                                >
                                                    <span>Uitloggen</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Navbar>
                                <main className="flex-1">
                                    <Outlet/>
                                </main>
                                <footer>
                                    <span className="block border-t border-blue-gray-50"/>
                                    <p className="block font-sans text-base antialiased font-normal leading-relaxed text-center text-blue-gray-900 mb-4 mt-4"> ©
                                        2024 GBI Dashboard - Momenteel ingelogd
                                        als <strong>{userdata["name"]["first"]} {userdata["name"]["last"]} - {gbidata["name"]}</strong> | <button
                                            onClick={(e) => logoutUser(e)}>Uitloggen</button></p>
                                </footer>
                            </div>
                        )
                    } else {
                        navigate(`/dashboard`)
                    }
                } else {
                    navigate(`/login?redirectTo=${prevLocation.pathname}`)
                }
            })
        }
    });

    const [page, setPage] = useState()
    useEffect(() => {
        return () => page;
    }, [page]);

    const logoutUser = async (e) => {
        e.preventDefault();

        await signOut(auth);
        navigate("/");
    }

    /* IN UL
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="flex items-center gap-x-2 p-1 font-medium"
        >
            <div>
                <FontAwesomeIcon icon={faHouse}/>
            </div>
            <a href="/" className="flex items-center">
                Home
            </a>
        </Typography>
    */

    return (
        page
    );
}


export default AdminLayoutLoggedin;